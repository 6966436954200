<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom"
        class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 *ngIf="project">{{project.name}}<br class="d-md-none"><small class="text-muted ms-md-3"
          routerLink="/clients/{{project.clientId}}" style="cursor: pointer;">{{project.clientName}}</small></h2>
    </div>
    <div>
      <button type="button" class="btn btn-danger" (click)="openDeleteProjectConfirmationModal();">
        <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
        <span>Delete</span>
      </button>
      <button *ngIf="project" type="button" class="btn btn-info ms-2" routerLink="/projects/{{project.id}}/summary">
        <fa-icon class="button-icon-left" [icon]="faEye"></fa-icon>
        <span>Summary</span>
      </button>
      <button class="btn btn-primary ms-2 mt-md-0" (click)="updateProject();">
        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
        <span>Save</span>
      </button>
    </div>
  </nav>
  <div class="content-wrap">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>General</h5>
          </div>
          <div class="card-body">
            <form [formGroup]="projectForm">
              <div class="row">
                <div class="col-sm">
                  <div class="row">
                    <div class="col-sm-6">
                      <label for="PROJECT_NAME">Name</label>
                      <input *ngIf="projectNameTypeaheadInput" id="PROJECT_NAME" type="text" class="form-control"
                        placeholder="Lighting" formControlName="name" maxlength="150" required
                        [ngbTypeahead]="projectNameTypeaheadInput"
                        (focus)="projectNameTypeaheadInputFocus$.next($any($event).target.value)"
                        [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                      <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                        <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150
                          characters.</div>
                      </div>
                    </div>
                    <div class="col-sm-6 mt-3 mt-md-0">
                      <label for="PROJECT_CATEGORY">Category</label>
                      <input *ngIf="projectCategoryTypeaheadInput" id="PROJECT_CATEGORY" type="text"
                        class="form-control" placeholder="Christmas" formControlName="category" maxlength="150" required
                        [ngbTypeahead]="projectCategoryTypeaheadInput"
                        (focus)="projectCategoryTypeaheadInputFocus$.next($any($event).target.value)"
                        [class.is-invalid]="category.invalid && (category.dirty || category.touched)">
                      <div *ngIf="category.invalid && (category.dirty || category.touched)">
                        <div *ngIf="category.errors.required" class="input-validation-msg">Category is required.</div>
                        <div *ngIf="category.errors.maxlength" class="input-validation-msg">Category cannot exceed 150
                          characters.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 mt-3">
                      <label for="PROJECT_YEAR">Year</label>
                      <input id="PROJECT_YEAR" type="number" class="form-control" placeholder="202X"
                        formControlName="year" min="0" required
                        [class.is-invalid]="year.invalid && (year.dirty || year.touched)">
                      <div *ngIf="year.invalid && (year.dirty || year.touched)">
                        <div *ngIf="year.errors.required" class="input-validation-msg">Year is required.</div>
                        <div *ngIf="year.errors.min" class="input-validation-msg">Year cannot be less than 0.</div>
                      </div>
                    </div>
                    <div class="col-sm-3 mt-3">
                      <label for="PROJECT_STORAGE_LOCATION" class="d-none d-xl-block">Storage Location</label>
                      <label for="PROJECT_STORAGE_LOCATION" class="d-xl-none">Stor. Loc.</label>
                      <input *ngIf="projectStorageLocationTypeaheadInput" id="PROJECT_STORAGE_LOCATION" type="text"
                        class="form-control" placeholder="Shelf 1A" formControlName="storageLocation" maxlength="150"
                        [ngbTypeahead]="projectStorageLocationTypeaheadInput"
                        (focus)="projectStorageLocationTypeaheadInputFocus$.next($any($event).target.value)"
                        [class.is-invalid]="storageLocation.invalid && (storageLocation.dirty || storageLocation.touched)">
                      <div *ngIf="storageLocation.invalid && (storageLocation.dirty || storageLocation.touched)">
                        <div *ngIf="storageLocation.errors.maxlength" class="input-validation-msg">Storage Location
                          cannot exceed 150 characters.</div>
                      </div>
                    </div>
                    <div class="col-sm-3 mt-3">
                      <label for="PROJECT_STAGE">Stage</label>
                      <select id="PROJECT_STAGE" class="form-select" formControlName="stage"
                        (change)="updateProject();">
                        <option value="PROPOSAL">Proposal</option>
                        <option value="INVOICE">Invoice</option>
                        <option value="PREPARATION">Inventory Prep</option>
                        <option value="INSTALLATION">Installation</option>
                        <option value="BASES">Bases</option>
                        <option value="REMOVAL">Removal</option>
                        <option value="REVIEWS">Reviews</option>
                      </select>
                    </div>
                    <div class="col-sm-3 mt-3">
                      <label for="PROJECT_STATUS">Status</label>
                      <select id="PROJECT_STATUS" class="form-select" formControlName="status"
                        (change)="updateProjectStatus($any($event).target.value, true);">
                        <option value="IN PROGRESS">In Progress</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="LOST">Lost</option>
                        <option value="CANCELED">Canceled</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="row">
                    <div class="col-sm mt-3 mt-md-0">
                      <label for="PROJECT_SALESPERSON">Salesperson</label>
                      <select id="PROJECT_SALESPERSON" class="form-select" formControlName="salesperson"
                        appForbiddenValue="SAU" required (change)="updateProject()"
                        [class.is-invalid]="salespersonCTRL.invalid && (salespersonCTRL.dirty || salespersonCTRL.touched)">
                        <option value="SAU">Select a User</option>
                        <option *ngFor="let user of users" [value]="user.id">{{user.firstName + ' ' + user.lastName}}
                        </option>
                      </select>
                      <!-- <input *ngIf="project" id="PROJECT_SALESPERSON" class="form-control"
                        value="{{salesperson.firstName}} {{salesperson.lastName}}" disabled readonly> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm mt-3">
                      <label for="PROJECT_UPDATED">Updated</label>
                      <input *ngIf="project" id="PROJECT_UPDATED" class="form-control"
                        value="{{project.updatedAt | formatDateTime}} by {{updatedBy.firstName}} {{updatedBy.lastName}}"
                        disabled readonly>
                    </div>
                    <div class="col-sm mt-3">
                      <label for="PROJECT_CREATED">Created</label>
                      <input *ngIf="project" id="PROJECT_CREATED" class="form-control"
                        value="{{project.createdAt | formatDateTime}} by {{createdBy.firstName}} {{createdBy.lastName}}"
                        disabled readonly>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Files -->
      <div class="col-xl-4 col-lg-6 mt-3">
        <app-project-files [projectId]="projectId"></app-project-files>
      </div>
      <!-- Mockups -->
      <div class="col-xl-4 col-lg-6 mt-3">
        <app-project-mockups [projectId]="projectId"></app-project-mockups>
      </div>
      <!-- Notes -->
      <div class="col-xl-4 col-lg-6 mt-3">
        <app-notes [referenceId]="projectId" [referenceIds]="[projectId]" type="PROJECT"
          cardBodyHeight="259px"></app-notes>
      </div>
    </div>
    <div *ngIf="project" class="row">
      <div class="col-12">
        <ul ngbNav #projectStageNav="ngbNav" class="nav-tabs mt-3" [activeId]="getProjectStageNumber(project.stage)">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Proposals</a>
            <ng-template ngbNavContent>
              <app-project-proposal [project]="project" (refresh)="getProject()"></app-project-proposal>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" [ngClass]="getProjectStageNumber(project.stage) < 2 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 2">
            <a ngbNavLink>Invoices</a>
            <ng-template ngbNavContent>
              <app-project-invoices [project]="project"></app-project-invoices>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" [ngClass]="getProjectStageNumber(project.stage) < 3 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 3">
            <a ngbNavLink>Inventory Prep</a>
            <ng-template ngbNavContent>
              <div class="row mt-3">
                <div class="col-12">
                  <ul ngbNav #nav="ngbNav" class="nav-tabs project-tabs">
                    <li [ngbNavItem]="1" class="inner-tab">
                      <a ngbNavLink>Pending</a>
                      <ng-template ngbNavContent>
                        <app-project-prep-global [projectId]="projectId"></app-project-prep-global>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="inner-tab">
                      <a ngbNavLink>Orders</a>
                      <ng-template ngbNavContent>
                        <app-project-prep-orders [projectId]="projectId"
                          (allCheck)="onListenOnCheckins($event)"></app-project-prep-orders>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="inner-tab">
                      <a ngbNavLink>Inventory</a>
                      <ng-template ngbNavContent>
                        <app-project-prep-inventory [projectId]="projectId"
                          (allCheck)="onListenOnCheckins($event)"></app-project-prep-inventory>
                      </ng-template>
                    </li>
                    <li ngbNavItem [disabled]="true" class="ms-auto">
                      <button class="btn btn-primary ms-2" (click)="updateProjectStage()">
                        <span>Next Stage</span>
                        <fa-icon class="button-icon-right" [icon]="faArrowRight"></fa-icon>
                      </button>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
              </div>
              <!-- <app-project-prep [projectId]="project.id"></app-project-prep> -->
            </ng-template>
          </li>
          <li [ngbNavItem]="4" [ngClass]="getProjectStageNumber(project.stage) < 4 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 4">
            <a ngbNavLink>Installation</a>
            <ng-template ngbNavContent>
              <app-project-installation [projectId]="project.id"></app-project-installation>
            </ng-template>
          </li>
          <li [ngbNavItem]="5" [ngClass]="getProjectStageNumber(project.stage) < 5 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 5">
            <a ngbNavLink>Bases</a>
            <ng-template ngbNavContent>
              <app-project-bases [project]="project"></app-project-bases>
            </ng-template>
          </li>
          <li [ngbNavItem]="6" [ngClass]="getProjectStageNumber(project.stage) < 6 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 6">
            <a ngbNavLink>Removal</a>
            <ng-template ngbNavContent>
              <app-project-removal [project]="project"></app-project-removal>
            </ng-template>
          </li>
          <li [ngbNavItem]="7" [ngClass]="getProjectStageNumber(project.stage) < 7 ? 'disabled-tab' : ''" [disabled]="getProjectStageNumber(project.stage) < 7">
            <a ngbNavLink>Reviews</a>
            <ng-template ngbNavContent>
              <app-project-reviews [project]="project"></app-project-reviews>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="projectStageNav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>

<!-- Unpaid Invoice Confirmation Modal -->
<ng-template #UNPAID_INVOICE_CONFIRMATION_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Unpaid Invoices</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <p class="mb-0">This project has unpaid invoices. Do you still wish to mark it as complete.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="updateProjectStatus('COMPLETED');">Continue</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss(); revertProjectStatus();">Cancel</button>
  </div>
</ng-template>
