import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.userAuthenticated.next(this.authService.isAuthenticated());
    let login = JSON.parse(localStorage.getItem("login"))
    if (!this.authService.isAuthenticated()) {
      sessionStorage.setItem('LastRoute', state.url.toString());
      this.router.navigateByUrl('/login', { replaceUrl: true });
      return false;
    }
    if((login?.subscription === 29 || !login?.isWithCardOnFile) && login?.role === "SUPER" && state.url != "/change-subscription"){
      this.router.navigateByUrl('/change-subscription', { replaceUrl: true });
      return false;
      
    }
    return true;
  }
}
