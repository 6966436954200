import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { User } from '../user';
import { AlertService } from '../alert.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faEye, faSave, faTrashCan, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { UserService } from '../user.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {

  // Modal
  private modalReference: NgbModalRef;
  @ViewChild('UNPAID_INVOICE_CONFIRMATION_MODAL', { static: false }) private unpaidInvoiceConfirmationModal: NgbModalRef;

  // Properties
  project: Project;
  projectId: string;
  hasUnpaidInvoices: boolean = false;
  salesperson: User;
  createdBy: User;
  updatedBy: User;
  users: User[];

  // Project Form
  projectForm: UntypedFormGroup;

  // Project Names
  projectNameTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectNameTypeaheadInputFocus$ = new Subject<string>();

  // Project Categories
  projectCategoryTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectCategoryTypeaheadInputFocus$ = new Subject<string>();

  // Project Storage Location
  projectStorageLocationTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectStorageLocationTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faEye = faEye;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faArrowRight = faArrowRight;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private userService: UserService,
    public location: Location) {
    this.route.paramMap.subscribe((res: any) => {
      this.projectId = res.params.projectId;
    });
  }

  ngOnInit(): void {
    this.getListOfUsers();
    // this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.projectForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      category: new UntypedFormControl(),
      year: new UntypedFormControl(),
      storageLocation: new UntypedFormControl(),
      stage: new UntypedFormControl('PROPOSAL'),
      status: new UntypedFormControl('IN PROGRESS'),
      salesperson: new UntypedFormControl(),
    });
    this.listenForProjectUpdates();
    this.getProject();
    this.prepareProjectNameTypeahead();
    this.prepareProjectCategoryTypeahead();
    this.prepareProjectStorageLocationTypeahead();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Delete Project Confirmation Modal
  openDeleteProjectConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this project?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteProject();
    });
  }

  // Open Unpaid Invoice Confirmation Modal
  openUnpaidInvoiceConfirmationModal(): void {
    this.modalReference = this.modalService.open(this.unpaidInvoiceConfirmationModal);
  }

  // Listen For Project Updates
  private listenForProjectUpdates(): void {
    this.projectService.projectUpdated.subscribe(() => {
      this.getProject();
      this.alertService.showSuccessAlert('Project Updated');
    });
  }

  // Get Project
  getProject(): void {
    this.projectService.getProject(this.projectId).subscribe((res) => {
      this.project = res.project;
      this.hasUnpaidInvoices = res.hasUnpaidInvoices;
      this.salesperson = res.salesperson;
      this.createdBy = res.createdBy;
      this.updatedBy = res.updatedBy;
      this.setProjectFields();
    });
  }

  // Set Project Fields
  private setProjectFields(): void {
    let login = JSON.parse(localStorage.getItem("login"))

    this.projectForm.controls.name.setValue(this.project.name);
    this.projectForm.controls.category.setValue(this.project.category);
    this.projectForm.controls.year.setValue(this.project.year);
    this.projectForm.controls.storageLocation.setValue(this.project.storageLocation);
    this.projectForm.controls.stage.setValue(this.project.stage);
    this.projectForm.controls.status.setValue(this.project.status);
    this.projectForm.controls.salesperson.setValue(this.salesperson.id);
    if (login && login.role == 'USER') this.projectForm.controls.salesperson.disable()
  }

  // Get Project Names
  private getProjectNames(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_name'
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Name Typeahead
  private prepareProjectNameTypeahead(): void {
    this.projectNameTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectNameTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectNames((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Project Categories
  private getProjectCategories(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_category',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_category'
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Category Typeahead
  private prepareProjectCategoryTypeahead(): void {
    this.projectCategoryTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectCategoryTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectCategories((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Project Storage Locations
  private getProjectStorageLocations(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_category',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_storage_location',
      hideBlanks: true
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Storage Location Typeahead
  private prepareProjectStorageLocationTypeahead(): void {
    this.projectStorageLocationTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectStorageLocationTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectStorageLocations((term.length == 0) ? null : term);
      }));
    }
  }

  // Update Project
  updateProject(): void {
    if (this.projectForm.valid) {
      const project = {
        id: this.projectId,
        name: this.projectForm.value.name,
        category: this.projectForm.value.category,
        year: this.projectForm.value.year,
        storageLocation: this.projectForm.value.storageLocation,
        salesperson: this.projectForm.value.salesperson || this.salesperson.id,
        stage: this.projectForm.value.stage,
        status: this.projectForm.value.status,
      };
      this.projectService.updateProject(project).subscribe(() => {
        this.alertService.showSuccessAlert('Project Updated');
        this.getProject();
      });
    } else {
      this.projectForm.markAllAsTouched();
    }
  }

  // Update Project Status
  updateProjectStatus(status: string, confirm: boolean = false): void {
    if (confirm && status == 'COMPLETED' && this.hasUnpaidInvoices) {
      this.openUnpaidInvoiceConfirmationModal();
      return;
    }
    this.projectService.updateProjectStatus(this.project.id, status).subscribe(() => {
      this.projectService.projectUpdated.emit();
      if (this.modalReference) this.modalReference.close();
    });
  }

  // Revert Project Status
  revertProjectStatus(): void {
    this.projectForm.controls.status.setValue(this.project.status);
  }

  // Delete Project
  deleteProject(): void {
    this.projectService.deleteProject(this.projectId).subscribe(() => {
      this.router.navigateByUrl(`/projects`, { replaceUrl: true });
    });
  }

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.projectId, 'INSTALLATION').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  onListenOnCheckins(allCheckedIn: boolean) {
    if (allCheckedIn) this.getProject();
  }

  getListOfUsers() {
    this.userService.getUsers().subscribe(users => {
      this.users = users
    })
  }

  // Project Form Accessors
  get name() { return this.projectForm.controls.name; }
  get category() { return this.projectForm.controls.category; }
  get year() { return this.projectForm.controls.year; }
  get storageLocation() { return this.projectForm.controls.storageLocation; }
  get salespersonCTRL() { return this.projectForm.controls.salesperson; }

  /* ----- Helper Functions ----- */

  // Get Project Stage Number
  getProjectStageNumber(stage: string): number {
    switch (stage) {
      case 'PROPOSAL':
        return 1;
      case 'INVOICE':
        return 2;
      case 'PREPARATION':
        return 3;
      case 'INSTALLATION':
        return 4;
      case 'BASES':
        return 5;
      case 'REMOVAL':
        return 6;
      case 'REVIEWS':
        return 7;
      default:
        return 0;
    }
  }
}
