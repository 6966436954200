<div class="card">
    <div class="card-header">
        <h5>Payment Methods</h5>
    </div>
    <div class="card-body">
        <div class="scrollable" style="height: 296px;">
            <!-- Card On File -->
            <div *ngIf="cardOnFile" class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="my-auto">
                            <img [src]="cardOnFile.url" width="50" height="auto">
                        </div>
                        <div class="my-auto ms-3">&#8226;&#8226;&#8226;&#8226; <span
                                style="font-weight: 600;">{{cardOnFile.lastFour}}</span>, Exp <span
                                style="font-weight: 600;">{{cardOnFile.expiration}}</span></div>
                        <button type="button" class="btn btn-primary my-auto ms-auto" (click)="openCardOnFileModal();">
                            <fa-icon [icon]="faPencil"></fa-icon>
                            <!-- <span>Edit</span> -->
                        </button>
                        <button type="button" class="btn btn-danger my-auto ms-2" *ngIf="isSupport"
                            (click)="openDeleteCardOnFileConfirmationModal();">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- No Card On File -->
            <div *ngIf="!cardOnFile" class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="my-auto">
                            <img [src]="getCardPaymentIcon()" width="50" height="auto">
                        </div>
                        <span class="my-auto ms-3" style="font-weight: 600;">No Card on File</span>
                        <button type="button" class="btn btn-primary my-auto ms-auto" (click)="openCardOnFileModal();">
                            <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>