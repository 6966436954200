import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConversationService {

  twilioClient = new BehaviorSubject(this.client)
  // isDialingValue = new BehaviorSubject(this.isDialing);
  connectionInitialiazedValue = new BehaviorSubject(this.connectionInitialiazed);
  connectionStateFailedValue = new BehaviorSubject(this.connectionStateFailed);
  conversationAddedValue = new BehaviorSubject(this.conversationAdded);
  tokenExpiredValue = new BehaviorSubject(this.tokenExpired);
  messageAddedValue = new BehaviorSubject(this.messageAdded);
  typingStartedValue = new BehaviorSubject(this.typingStarted);
  connectionErrorValue = new BehaviorSubject(this.connectionError);
  chatListValue = new BehaviorSubject(this.chatList);
  typingEndedValue = new BehaviorSubject(this.typingEnded);
  unreadMessagesValue = new BehaviorSubject(this.unreadMessages);
  unreadMessagesCountValue = new BehaviorSubject(this.unreadMessagesCount);
  // incomingCallValue = new BehaviorSubject(this.incomingCall);
  selectedContact: any = null;


  set unreadMessagesCount(value: any) {
    this.unreadMessagesCountValue.next(value);
  }

  get unreadMessagesCount() {
    return this.unreadMessagesCountValue;
  }

  set unreadMessages(value: any) {
    this.unreadMessagesValue.next(value);
  }

  get unreadMessages() {
    return this.unreadMessagesValue;
  }

  set chatList(value: any) {
    this.chatListValue.next(value);
  }

  get chatList() {
    return this.chatListValue;
  }

  set connectionError(value: any) {
    this.connectionErrorValue.next(value);
    this.selectedContact = value;
  }

  get connectionError() {
    return this.connectionErrorValue;
  }

  set typingEnded(value: any) {
    this.typingEndedValue.next(value);
  }

  get typingEnded() {
    return this.typingEndedValue;
  }

  set typingStarted(value: any) {
    this.typingStartedValue.next(value);
  }

  get typingStarted() {
    return this.typingStartedValue;
  }

  set messageAdded(value: any) {
    this.messageAddedValue.next(value);
  }

  get messageAdded() {
    return this.messageAddedValue;
  }

  set conversationAdded(value: any) {
    this.conversationAddedValue.next(value);
  }

  get conversationAdded() {
    return this.conversationAddedValue;
  }

  set tokenExpired(value: any) {
    this.tokenExpiredValue.next(value);
    this.selectedContact = value;
  }

  get tokenExpired() {
    return this.tokenExpiredValue;
  }

  set connectionStateFailed(value: any) {
    this.connectionStateFailedValue.next(value);
    this.selectedContact = value;
  }

  get connectionStateFailed() {
    return this.connectionStateFailedValue;
  }

  set connectionInitialiazed(value: any) {
    this.connectionInitialiazedValue.next(value);
  }

  get connectionInitialiazed() {
    return this.connectionInitialiazedValue;
  }



  // set incomingCall(value: any) {
  //   this.incomingCallValue.next(value);
  //   this.selectedContact = value;
  // }

  // get incomingCall() {
  //   return this.incomingCallValue;
  // }

  set client(value: any) {
    this.twilioClient.next(value);
  }

  get client() {
    return this.twilioClient;
  }

  constructor(private http: HttpClient) { }

  sendMessage(data: any): Observable<any> {
    return this.http.post<any>(`/messaging/newmessage`, data);
  }

  updateContactConversation(conversationId: string, contactId: string): Observable<any> {
    return this.http.post<any>(`/messaging/addconversationid`, {
      conversationId, contactId
    });
  }

  newConversation(contactName: string, contactId: string, recipient: string): Observable<any> {
    const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;
    return this.http.post<any>(`/messaging/newconversation`, {
      defaultNumber, contactName, contactId, recipient
    });
  }

  getToken(): Observable<any> {
    // TODO remove mock data
    const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;
    const sid = JSON.parse(localStorage.getItem('login'))?.twilioSID;

    return this.http.post<any>(`/messaging/generatetoken`, {
      identity: defaultNumber
    });
  }

  deleteConversation(conversationId, contactId = null) {
    return this.http.delete(`/messaging/deleteintwilio/${conversationId}/${contactId}`)
  }

  callHistory(body): Observable<any> {
    return this.http.post<any>(`/subacc/callhistory`, body);
  }
  
  voiceMails(body): Observable<any> {
    return this.http.post<any>(`/subacc/voicemails`, body);
  }

  getContactByNumber(number: string) {
    return this.http.get<any>(`/messaging/getcontactbynumber/${number}`);
  }

  updateConversation(conversationId: string, propertyToUpdate: any) {
    return this.http.put<any>(`messaging/updateconversation`, {
      conversationId, propertyToUpdate
    });

  }

}
