<div class="offcanvas-header" style="grid-row: 1;">
    <div class="d-flex flex-column">
        <h4 class="offcanvas-title" style="line-height: 1.25;">Notifications</h4>
        <small>{{unreadNotificationCount}} Unread | {{notifications.length}} Total</small>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss();"></button>
</div>
<div class="offcanvas-body pt-0" style="grid-row: 2; height: 100%;">
    <div class="d-flex">
        <button class="btn btn-outline-primary w-100" type="button" (click)="readAllNotifications();" [disabled]="unreadNotificationCount == 0">
            <fa-icon [icon]="faEnvelopeOpen" class="button-icon-left"></fa-icon>
            <span>Read All</span>
        </button>
        <button class="btn btn-outline-danger w-100 ms-2" type="button" (click)="deleteAllNotifications();" [disabled]="notifications.length == 0">
            <fa-icon [icon]="faTrashCan" class="button-icon-left"></fa-icon>
            <span>Delete All</span>
        </button>
    </div>
    <div class="mt-3">
        <ul class="list-group scrollable">
            <li *ngFor="let notification of notifications" class="list-group-item ps-2 pe-0 py-3" [style]="{ 'cursor': notificationService.isClickable(notification.type) ? 'pointer' : 'normal' }" (click)="notificationService.goTo(notification); offcanvas.close();">
                <div class="d-flex">
                    <div class="d-flex">
                        <fa-icon [icon]="faCircle" size="xs" class="my-auto" [style.color]="(notification.isRead) ? 'lightgray' : 'var(--green-color)'"></fa-icon>
                    </div>
                    <div class="d-flex flex-column mx-2 my-auto">
                        <strong>{{notification.title}}</strong>
                        <div style="font-size: 15px; word-break: break-word;">{{notification.message}}</div>
                        <small class="text-muted">{{notification.createdAt | formatDateTime}}</small>
                        <div *ngIf="notification.type == 'VOICEMAIL' && notification.externalUrl">
                            <audio controls style="width: 75%">
                                <source [src]="notification.externalUrl + '.mp3'" type="audio/mpeg">
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </div>
                    
                    <div class="d-flex flex-column ms-auto" style="margin-top: -1rem; margin-bottom: -1rem;">
                        <div class="d-flex flex-column" style="height: 100%;">
                            <button *ngIf="!notification.isRead" type="button" class="read-btn" (click)="$event.stopPropagation(); readNotification(notification);" ngbTooltip="Mark as Read" placement="left">
                                <fa-icon [icon]="faEnvelopeOpen"></fa-icon>
                            </button>
                            <button type="button" class="delete-btn" (click)="$event.stopPropagation(); deleteNotification(notification);" ngbTooltip="Delete" placement="left">
                                <fa-icon [icon]="faTrashCan"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </li>
            <li *ngIf="notifications.length == 0" class="list-group-item">No Notifications</li>
        </ul>
    </div>
</div>