import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { faAngleLeft, faCheck, faDownload, faPlus, faSave, faTrashCan, faUpload,
  faMousePointer, faPencil, faFillDrip, faSun, faHollyBerry, faLightbulb, faTriangleExclamation,
  faObjectGroup, faObjectUngroup, faImages, faImage, faTint, faCopy, faClipboard, faCut,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus} from '@fortawesome/free-solid-svg-icons';
import { ProjectFileService } from '../project-file.service';
import { Subject, debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MockupService } from '../mockup.service';
import { AlertService } from '../alert.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { MockupPresetAsset } from '../mockup-preset-asset';
import { FileService } from '../file.service';
import { ActiveSelection } from 'fabric/fabric-impl';

enum Mode {
  SELECT,
  DRAW
}

interface Point {
  x: number;
  y: number;
}

class LightGroup extends fabric.Group {
  meta: {
    type: 'light',
    spacing: number;
    size: number;
    color: string;
    isPatternActive?: boolean;
    colorPattern?: string[];
  };
  isAlreadyScaled: boolean = false; // Initialize to false
  constructor(objects?: fabric.Object[], options?: fabric.IGroupOptions) {
    super(objects, options);
    // Ensure the property is initialized
    this.isAlreadyScaled = false;
}
}

@Component({
  selector: 'app-mockup-tool',
  templateUrl: './mockup-tool.component.html',
  styleUrls: ['./mockup-tool.component.css']
})
export class MockupToolComponent implements OnInit, AfterViewInit, OnDestroy {
  // CANVAS viewchild
  @ViewChild('myCanvas', { static: false }) canvasRef!: ElementRef<HTMLCanvasElement>;
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
      this.resizeSubject.next((event.target as Window).innerWidth);
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: Event) {
    location.reload();
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Defaults
  private DEFAULT_TEXTURE_SIZE: number = 4096;
  private DEFAULT_LIGHT_SPACING: number = 20;
  private DEFAULT_LIGHT_SIZE: number = 3;
  private DEFAULT_LIGHT_COLOR: string = '#FFFFFF';

  private LIGHT_GROUP_PADDING: number = 5;

  // Properties
  private resizeObserver!: ResizeObserver;

  private projectId!: string;
  private mockupId!: string;
  mockupForm: UntypedFormGroup;

  lastUpdated: number;

  isSavingToFiles: boolean = false;

  private canvas!: fabric.Canvas;

  private fabricSerializeProperties: string[] = ['meta', 'lockScalingX', 'padding', 'customProperties'];

  mouse: any = { x: 0, y: 0, isDown: false, getPoint: () => { return { x: this.mouse.x, y: this.mouse.y }; } };

  ModeType = Mode;

  private prevMode: Mode = Mode.SELECT;
  mode: Mode = Mode.SELECT;

  private isDrawing: boolean = false;
  private isContinued: boolean = false;

  private startPoint!: Point;
  private endPoint!: Point;

  isMobileView: boolean = false;
  hideEditMockupTools: boolean = false;

  // Background Image
  isBackgroundFilteringAvailable: boolean = true;
  currentTextureSize: number;
  brightness: number = 0;
  private brightnessSubject$: Subject<number> = new Subject<number>();

  lightSpacing: number = this.DEFAULT_LIGHT_SPACING;
  lightSize: number = this.DEFAULT_LIGHT_SIZE;
  lightColor: string = this.DEFAULT_LIGHT_COLOR;
  lightColorPattern: string[] = [];
  isPatternActive: boolean = false;

  private prevCanvasWidth: number;
  private prevCanvasHeight: number;
  zoomPercentage: number = 100;

  //original views
  originalZoom = 1;  // Store the original zoom level
  originalViewportTransform: number[] = [];  // Store the original viewport transform
  originalWidth: number;  // Store the original width of the canvas
  originalHeight: number;  // Store the original height of the canvas

  @ViewChild('CANVAS_CONTAINER', { static: false }) private canvasContainer: ElementRef;

  // Preset Colors
  presetColors: string[] = ['#FFFFFF', '#FFDF88', '#F5C2CB', '#E93423', '#F1A93B', '#FFFF55', '#75FB4C', '#75FBFE', '#0000F4', '#B526BE'];
  presetColorNames: string[] = ['White', 'Warm White', 'Pink', 'Red', 'Orange', 'Yellow', 'Green', 'Cyan', 'Blue', 'Violet'];

  // Custom Colors
  customColors: string[] = [];

  // Color Patterns
  colorPatterns: string[][] = [];

  // Preset Assets
  presetAssetMap: Map<string, MockupPresetAsset[]> = new Map<string, MockupPresetAsset[]>();
  activePresetAssetCategory: string = 'BOW';
  activePresetAssets: MockupPresetAsset[] = [];

  // Selected Objects
  objectsSelected: boolean = false;

  // Image Filters
  isImageFilteringEnabled: boolean = false;
  selectedImageOpacity: number = 1;

  // Grouping
  isGroupingEnabled: boolean = false;

  // Ungrouping
  isUngroupingEnabled: boolean = false;

  // Copy Paste
  private clipboard: fabric.Object | null = null;


  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faCheck = faCheck;
  faPlus = faPlus;
  faUpload = faUpload;
  faDownload = faDownload;
  faMousePointer = faMousePointer;
  faPencil = faPencil;
  faFillDrip = faFillDrip;
  faSun = faSun;
  faHollyBerry = faHollyBerry;
  faImages = faImages;
  faLightbulb = faLightbulb;
  faTriangleExclamation = faTriangleExclamation;
  faObjectGroup = faObjectGroup;
  faObjectUngroup = faObjectUngroup;
  faTint = faTint;
  faCopy = faCopy;
  faClipboard = faClipboard;
  faCut = faCut;
  faMagnifyingGlassPlus = faMagnifyingGlassPlus;
  faMagnifyingGlassMinus = faMagnifyingGlassMinus;
  onLoad: boolean = false;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private mockupService: MockupService,
    private projectFileService: ProjectFileService,
    private fileService: FileService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public location: Location,
    private router: Router) {
    (fabric as any).isWebglSupported(this.DEFAULT_TEXTURE_SIZE);
    const maxTextureSize = (fabric as any).maxTextureSize
    fabric.textureSize = (maxTextureSize < this.DEFAULT_TEXTURE_SIZE) ? maxTextureSize : this.DEFAULT_TEXTURE_SIZE;
    this.currentTextureSize = fabric.textureSize;

    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      console.log(width)

      if (width <= 600) {
      this.checkScreenSize(width);
      this.reloadCanvasOnResize();

      }
      else if(width > 600 && width <= 991) {
        this.hideEditMockupTools = true;
        this.isMobileView = false;
      this.reloadCanvasOnResize();

      }
      else if(width >= 992) {
        this.hideEditMockupTools = false;
        this.isMobileView = false;
        this.reloadCanvasOnResize();

      }


    });
  }

  ngOnInit(): void {
    this.onLoad = true;
    this.mockupId = this.route.snapshot.queryParamMap.get('mockupId');
    this.mockupForm = new UntypedFormGroup({
      name: new UntypedFormControl()
    });
    this.getPresetAssets();
    this.loadStoredCustomColors();
    this.loadStoredColorPatterns();
    if (this.mockupId) this.getMockup();
    this.listenForBackgroundImageBrightnessChanges();

    this.checkScreenSize();
  }

  ngAfterViewInit(): void {
    // Resize Observer
    this.resizeObserver = new ResizeObserver(() => {
        this.prevCanvasWidth = this.canvas.width;
        this.prevCanvasHeight = this.canvas.height;
        const bgImg = this.canvas.backgroundImage as fabric.Image;
        if (!bgImg) return;
        if (bgImg && this.onLoad) {
            this.isBackgroundFilteringAvailable = (bgImg.width <= fabric.textureSize && bgImg.height <= fabric.textureSize);
            this.scaleBackgroundImage(bgImg);
            this.canvas.setDimensions({ width: bgImg.width * bgImg.scaleX, height: bgImg.height * bgImg.scaleY });
            this.reloadCanvasObjects();
            this.canvas.requestRenderAll();
        }
    });

    this.resizeObserver.observe(this.canvasContainer.nativeElement);
    this.onLoad = false;
    // Canvas Initialization
    this.canvas = new fabric.Canvas('CANVAS', {
        containerClass: 'mockup-tool-canvas-container',
        selection: true
    });

    // Get the original dimensions of the background image
    const bgImg = this.canvas.backgroundImage as fabric.Image;
    let minZoom = 1;  // Default minZoom

    if (bgImg) {
        minZoom = Math.min(
            this.canvas.getWidth() / bgImg.width!,
            this.canvas.getHeight() / bgImg.height!
        );
    }

    // Store the initial zoom and viewport transform
    this.originalZoom = this.canvas.getZoom();
    this.originalViewportTransform = [...this.canvas.viewportTransform!]; // Clone the initial viewport transform

    // Implement Zoom functionality with mouse wheel
    // this.canvas.on('mouse:wheel', (opt) => {
    //   let delta = opt.e.deltaY;
    //   let zoom = this.canvas.getZoom();
    //   zoom = zoom - delta / 400;  // Adjust zoom speed

    //   // Limit zoom to maximum and minimum values
    //   if (zoom > 20) zoom = 20;  // Maximum zoom

    //   // Check if the zoom is below the minZoom (original zoom level)
    //   if (zoom <= minZoom) {
    //       // Reset the zoom and pan if we go below the original zoom level
    //       zoom = minZoom;  // Ensure we stay at minZoom
    //       this.resetZoomAndPan();
    //   } else {
    //       // Get mouse pointer location for zoom
    //       const pointer = this.canvas.getPointer(opt.e);
    //       const zoomPoint = new fabric.Point(pointer.x, pointer.y);

    //       // Zoom relative to mouse pointer
    //       this.canvas.zoomToPoint(zoomPoint, zoom);
    //   }

    //   opt.e.preventDefault();
    //   opt.e.stopPropagation();

    //   this.updateViewport();  // Call updateViewport to adjust the viewport and render
    //   this.updateZoomPercentage(zoom);
    // });

    // Add the panning functionality
    let isPanning = false;   // To track panning state
    let lastPosX = 0;        // To store the last X position of the mouse
    let lastPosY = 0;        // To store the last Y position of the mouse

    // this.canvas.on('mouse:down', (opt) => {
    //     const evt = opt.e;
    //     if (evt.altKey) {  // Use Alt key to enable panning
    //         isPanning = true;
    //         lastPosX = evt.clientX;
    //         lastPosY = evt.clientY;
    //         this.canvas.selection = false;  // Disable selection while panning
    //     }
    // });

    this.canvas.on('mouse:move', (opt) => {
        if (isPanning) {
            const evt = opt.e;
            const vpt = this.canvas.viewportTransform!;
            vpt[4] += evt.clientX - lastPosX;  // Update X-axis translation
            vpt[5] += evt.clientY - lastPosY;  // Update Y-axis translation
            this.canvas.requestRenderAll();  // Re-render the canvas
            lastPosX = evt.clientX;  // Update last X position
            lastPosY = evt.clientY;  // Update last Y position
        }
    });

    this.canvas.on('mouse:up', () => {
        isPanning = false;
        this.canvas.selection = true;  // Re-enable selection after panning
    });

    this.canvas.on('object:modified', (event) => {
        const obj = event.target;
        obj.setCoords();  // Update coordinates after any modification (scaling, moving, etc.)
    });

    this.canvas.on('object:scaling', (event) => {
        const obj = event.target;
        obj.setCoords();  // Ensure object coordinates are updated after scaling
    });

    this.canvas.on('selection:created', (event) => {
        this.objectsSelected = event.selected !== undefined && event.selected.length > 0;
        const selectedObjects = this.canvas.getActiveObjects();
        if (this.mode === Mode.SELECT && selectedObjects.length > 1) {
            this.canvas.getActiveObject().hasControls = false;
            this.isImageFilteringEnabled = false;
            this.isGroupingEnabled = true;
        }
    });

    this.canvas.on('selection:updated', (event) => {
        this.objectsSelected = event.selected !== undefined && event.selected.length > 0;
        const selectedObjects = this.canvas.getActiveObjects();
        if (this.mode === Mode.SELECT && selectedObjects.length > 1) {
            this.canvas.getActiveObject().hasControls = false;
            this.isImageFilteringEnabled = false;
            this.isGroupingEnabled = true;
        } else {
            this.isGroupingEnabled = false;
        }
    });

    this.canvas.on('selection:cleared', () => {
        this.objectsSelected = false;
        this.isImageFilteringEnabled = false;
        this.isGroupingEnabled = false;
        this.isUngroupingEnabled = false;
    });

    // Mouse Events
    this.canvas.on('mouse:down', (event) => {
        this.handleMouseEvents(event);
        switch (this.mode) {
            case Mode.DRAW:
                this.drawMouseDown(event);
                break;
            default:
                break;
        }
    });

    this.canvas.on('mouse:up', (event) => {
        this.handleMouseEvents(event);
    });

    this.canvas.on('mouse:move', (event) => {
        this.handleMouseEvents(event);
    });

    // Keyboard Events
    document.addEventListener('keydown', (event) => {
        if (event.code == 'Delete' || event.code == 'Backspace') {
            this.deleteSelectedObjects();
        }

        // Check for Ctrl+C (copy) or Ctrl+V (paste)
        if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
            this.copySelected();
        }
        if (event.ctrlKey && (event.key === 'v' || event.key === 'V')) {
            this.pasteSelected();
        }
        if (event.ctrlKey && (event.key === 'x' || event.key === 'X')) {
            this.cutSelected();
        }
    });

    console.log(this.canvasRef);
  }

private reloadCanvasOnResize(): void {
   if (this.mockupId) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/mockupTool'], { queryParams: { mockupId: this.mockupId } });
    });
  }
}


/// Add the Reset Zoom and Pan function
resetZoomAndPan() {
  // Reset zoom level to the original value
  this.canvas.setZoom(this.originalZoom);

  // Reset viewport transform to the original values
  this.canvas.setViewportTransform([...this.originalViewportTransform]);

  // Re-render the canvas after resetting
  this.canvas.requestRenderAll();
}

// Update the Viewport
updateViewport() {
  const zoom = this.canvas.getZoom();
  const vpt = this.canvas.viewportTransform!;
  this.canvas.viewportTransform = [zoom, 0, 0, zoom, vpt[4], vpt[5]];  // Preserve panning values (vpt[4] and vpt[5])
  this.canvas.requestRenderAll(); // Re-render the canvas after updating the viewport
}

// Add the Zoom In function
zoomIn() {
  let zoom = this.canvas.getZoom();
  zoom = zoom * 1.1;  // Increase zoom by 10%

  if (zoom > 20) zoom = 20;  // Set max zoom limit

  this.canvas.zoomToPoint(new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2), zoom);
  this.updateViewport();
  this.updateZoomPercentage(zoom);
}

// Add the Zoom Out function
zoomOut() {
  let zoom = this.canvas.getZoom();
  zoom = zoom / 1.1;  // Decrease zoom by 10%

  if (zoom <= 1) {
      zoom = 1;  // Set minimum zoom level
      this.resetZoomAndPan();  // Call reset when zoom reaches minimum
  } else {
      this.canvas.zoomToPoint(new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2), zoom);
  }

  this.updateViewport();
  this.updateZoomPercentage(zoom);
}
   // Update the zoom percentage display in html
  updateZoomPercentage(zoom: number) {
    this.zoomPercentage = Math.round(zoom * 100);  // Convert zoom factor to percentage
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    document.removeAllListeners('keydown');
  }

  // Open Delete Mockup Confirmation Modal
  openDeleteMockupConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this mockup?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteMockup();
    });
  }

  // Reload Canvas Objects
  private reloadCanvasObjects(): void {
    const scaleX = this.canvas.width / this.prevCanvasWidth;
    const scaleY = this.canvas.height / this.prevCanvasHeight;

    // Discard active objects to avoid conflicts during reloading
    this.canvas.discardActiveObject();

    const groups = this.canvas.getObjects().filter((object) => object.isType('group'));
    for (const group of groups) {
        const lightGroup = group as LightGroup;
        if (lightGroup.meta !== undefined && lightGroup.meta.type === 'light') {
            const prevLeft = group.left!;
            const prevTop = group.top!;
            const prevAngle = group.angle!;

            // Check current group height before scaling
            console.log('Before scaling, group.height:', group.height);
            console.log('Before scaling, group.height:', scaleY);

            // Resize group (scale only if not already scaled)
            if (!lightGroup.isAlreadyScaled) {
                group.set({
                    height: (group.height * scaleY) - 1,
                    scaleY: 1  // Prevent distorting Y axis
                });
                lightGroup.isAlreadyScaled = true; // Set the flag to avoid future scaling
            }

            console.log('After scaling, group.height:', group.height);
            console.log('After scaling, group.height:', group);

            // Remove and re-add lights
            for (const light of lightGroup.getObjects() as fabric.Circle[]) {
              console.log('After scaling, light:', light);
                lightGroup.remove(light);
            }

            const newLights = this.getLights(lightGroup);  // Generate lights again with updated scaling
            console.log('newLights', newLights);
            for (const newLight of newLights) {
                lightGroup.addWithUpdate(newLight);
            }

            // Update position and angle
            console.log('LIGHT_GROUP_PADDING:', this.LIGHT_GROUP_PADDING);
            group.set({
                padding: this.LIGHT_GROUP_PADDING,
                left: prevLeft * scaleX,
                top: prevTop * scaleY,
                angle: prevAngle
            });

            // Update coordinates after scaling
            group.setCoords();
            group.setControlsVisibility({ bl: false, br: false, mb: false, ml: false, mr: false, tl: false, tr: false });

            // Apply event listeners again to the new light group
            this.setLightGroupEventListeners(lightGroup);
        } else {
            group.on('selected', () => {
                this.isUngroupingEnabled = true;
            });
        }
    }
  }

  // Load Customizations From Mockup
  private loadCustomizationsFromMockup(): void {
    const lightGroups = this.canvas.getObjects().filter((object) => {
      if (!object.isType('group')) return false;
      const lightGroup = object as LightGroup;
      return lightGroup.meta !== undefined && lightGroup.meta.type == 'light';
    }) as LightGroup[];
    for (const group of lightGroups) {
      if (!this.presetColors.includes(group.meta.color) && !this.customColors.includes(group.meta.color)) this.customColors.push(group.meta.color);
      const colorPatternStrings: string[] = this.colorPatterns.map((pattern) => { return pattern.join(';'); });
      if (group.meta.isPatternActive && group.meta.colorPattern) {
        const colorPatternString = group.meta.colorPattern.join(';');
        if (!colorPatternStrings.includes(colorPatternString)) this.colorPatterns.push(group.meta.colorPattern);
      }
    }
    localStorage.setItem('MockupTool.CustomColors', JSON.stringify(this.customColors));
    localStorage.setItem('MockupTool.ColorPatterns', JSON.stringify(this.colorPatterns));
  }

  // Delete Selected Objects
  deleteSelectedObjects(): void {
    const activeObjects = this.canvas.getActiveObjects();
    if (activeObjects.length > 0) {
      for (const selectedObject of activeObjects) this.canvas.remove(selectedObject);
      this.canvas.discardActiveObject();
      this.isDrawing = false;
    }
  }

  // Group Selected Objects
  groupSelectedObjects(): void {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject.isType('activeSelection')) return;
    const activeObjects = this.canvas.getActiveObjects();
    if (activeObjects.length < 2) return;
    const group = (activeObject as ActiveSelection).toGroup();
    // this.canvas.discardActiveObject();
    // for (const object of activeObjects) this.canvas.remove(object);
    // const group = new fabric.Group(activeObjects, {
    //   originX: activeObject.originX,
    //   originY: activeObject.originY,
    //   top: activeObject.top,
    //   left: activeObject.left
    // });
    group.on('selected', () => {
      this.isUngroupingEnabled = true;
    });
    // this.canvas.add(group);
    // this.canvas.setActiveObject(group);
    this.canvas.requestRenderAll();
  }

  // Ungroup Selected Objects
  ungroupSelectedObjects(): void {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject.isType('group')) return;
    if ((activeObject as LightGroup).meta !== undefined && (activeObject as LightGroup).meta.type == 'light') return;
    (activeObject as fabric.Group).toActiveSelection();
    this.canvas.discardActiveObject();
    // const activeGroup = activeObject as fabric.Group;
    // const objects = activeGroup.getObjects();
    // if (objects.length === 0) return;
    // activeGroup._restoreObjectsState();
    // this.canvas.remove(activeGroup);
    // for (const object of objects) this.canvas.add(object);
    this.canvas.requestRenderAll();
  }

  /* ----- Mockup Requests ----- */

  // Get Mockup
  private getMockup(): void {
    this.mockupService.getMockup(this.mockupId).subscribe((mockup) => {
      this.projectId = mockup.projectId;
      this.mockupForm.controls.name.setValue(mockup.name);
      this.lastUpdated = mockup.updatedAt;
      // Load Canvas State
      if (mockup.state) {
        this.canvas.loadFromJSON(mockup.state, () => {
          // Load Customizations
          this.loadCustomizationsFromMockup();
          // Load Background Image
          const bgImg = this.canvas.backgroundImage as fabric.Image;
          if (bgImg) {
            // Background Filtering
            this.isBackgroundFilteringAvailable = (bgImg.width <= this.currentTextureSize && bgImg.height <= this.currentTextureSize);
            const brightnessFilter = bgImg.filters.find((filter) => { return filter.toObject().type == 'Brightness'; });
            if (brightnessFilter) this.brightness = (brightnessFilter as any).brightness;
            // Dimensions & Scaling
            this.canvas.setDimensions({ width: bgImg.width * bgImg.scaleX, height: bgImg.height * bgImg.scaleY });
            this.prevCanvasWidth = this.canvas.width;
            this.prevCanvasHeight = this.canvas.height;
            // Reload Canvas Objects
            this.reloadCanvasObjects();
            // Render All
            this.canvas.requestRenderAll();
          }
          // Load Images
          const images = this.canvas.getObjects().filter((object) => { return object.isType('image') }) as fabric.Image[];
          for (const image of images) this.setImageAssetEventListeners(image);
        }, (jObj: fabric.Object, oObj: fabric.Object) => {
          if (jObj.type == 'image' && oObj === null) {
            this.createMissingImagePlaceholder(jObj as fabric.Image);
          }
        });
      } else {
        this.loadBackgroundImage();
      }
    });
  }

  // Save or Update Mockup
  updateMockup(): void {
    if (this.mockupForm.valid) {
      // Ensure that all necessary canvas properties are included in serialization
      // const serializedCanvas = this.canvas.toObject([...this.fabricSerializeProperties, ...['backgroundImage', 'objects', 'left', 'top', 'scaleX', 'scaleY', 'angle', 'flipX', 'flipY']]);
      // console.log('update Mockup serializedCanvas',JSON.stringify(serializedCanvas));
      const mockup: any = {
        id: this.mockupId,
        name: this.mockupForm.value.name,
        state:  this.canvas.toObject(this.fabricSerializeProperties)  // Include serialized canvas state with all important properties
      };

      // Save the mockup state via service
      this.mockupService.updateMockup(mockup).subscribe(() => {
        this.alertService.showSuccessAlert('Mockup Updated');
      });
    } else {
      this.mockupForm.markAllAsTouched();
    }
  }

  // Delete Mockup
  deleteMockup(): void {
    this.mockupService.deleteMockup(this.mockupId).subscribe(() => {
      this.alertService.showSuccessAlert('Mockup Deleted');
      this.location.back();
    });
  }

  // Save To Files
  async saveToFiles(): Promise<void> {
    try {
      this.isSavingToFiles = true;
      this.alertService.showInfoAlert('Saving...');
      this.canvas.setZoom(1)
      const bgImg = this.canvas.backgroundImage as fabric.Image;
      const dataUrl = this.canvas.toDataURL({
        format: 'png',
        left: bgImg.left,
        top: bgImg.top,
        width: bgImg.width * bgImg.scaleX,
        height: bgImg.height * bgImg.scaleY
      });
      const blob = this.createBlob(dataUrl);
      const data: any = {
        name: this.mockupForm.value.name + '.png',
        type: blob.type,
        size: blob.size,
        isFromMockup: true
      };
      const presignedUrl = await lastValueFrom(this.projectFileService.addProjectFile(this.projectId, data));
      this.alertService.showInfoAlert('Uploading...');
      await new Promise<void>((resolve, reject) => {
        this.fileService.uploadFileDirectToS3(presignedUrl, blob).subscribe((event: HttpEvent<any>) => {
          if (event.type == HttpEventType.UploadProgress) this.alertService.updateProgress(event.loaded, blob.size);
          if (event.type == HttpEventType.Response) {
            (event.ok) ? resolve() : reject();
          }
        });
      });
      this.alertService.showSuccessAlert('Mockup Image Saved');
      this.isSavingToFiles = false;
    } catch (error) {
      this.isSavingToFiles = false;
    }
  }

  // Create Blob
  private createBlob(dataUrl: string): Blob {
    var byteString = window.atob(dataUrl.split(',')[1]);
    var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = Uint8Array.from(byteString, (ch) => { return ch.charCodeAt(0); }).buffer;
    return new Blob([ab], { type: mimeString });
  }

  // Download Image
  downloadImage(): void {
    // Download
    const bgImg = this.canvas.backgroundImage as fabric.Image;
    const dataUrl = this.canvas.toDataURL({
      format: 'png',
      left: bgImg.left,
      top: bgImg.top,
      width: bgImg.width * bgImg.scaleX,
      height: bgImg.height * bgImg.scaleY
    });
    const mockupName = (this.mockupForm.value.name) ? this.mockupForm.value.name : 'MockupExport';
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.setAttribute('download', mockupName + '.png');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  /* ----- Mockup Form Accessors ----- */

  get name() { return this.mockupForm.controls.name; }

  /* ----- Mouse ----- */

  // Handle Mouse Events
  private handleMouseEvents(event: fabric.IEvent<MouseEvent>): void {
    this.mouse.x = event.pointer!.x;
    this.mouse.y = event.pointer!.y;
    if (event.e.type === 'mousedown' || event.e.type === 'touchstart') this.mouse.isDown = true;
    if (event.e.type === 'mouseup' || event.e.type === 'touchend') this.mouse.isDown = false;
  }

  /* --- Mouse - Draw Mode --- */

  // Draw Mouse Down
  private drawMouseDown(event: fabric.IEvent<MouseEvent>): void {
    // if (event.e.button !== 0) return;
    if (!this.isDrawing) this.startLightPath();
    else this.continueLightPath();
  }

  /* ----- Drawing & Light Controls ----- */

  // Change Mode
  changeMode(mode: Mode): void {
    this.prevMode = this.mode;
    this.mode = mode;
    if (mode === Mode.DRAW) {
      this.canvas.selection = false;
      this.canvas.defaultCursor = 'crosshair';
    } else {
      this.canvas.selection = true;
      this.canvas.defaultCursor = 'default';
      this.isDrawing = false;
    }
  }

  // Change Spacing
  changeSpacing(event: Event): void {
    this.lightSpacing = parseInt((event.target as HTMLInputElement).value);
    const lightGroups = this.canvas.getActiveObjects().filter((object) => {
      if (!object.isType('group')) return false;
      const lightGroup = object as LightGroup;
      return lightGroup.meta !== undefined && lightGroup.meta.type == 'light';
    }) as LightGroup[];
    if (lightGroups.length === 0) return;
    if (lightGroups.length > 1) this.canvas.discardActiveObject();
    for (const group of lightGroups) {
      const prevHeight = group.height!;
      const prevLeft = group.left!;
      const prevTop = group.top!;
      const prevAngle = group.angle!;
      for (const light of group.getObjects() as fabric.Circle[]) group.remove(light);
      const newLights = this.getLights(group);
      for (const newLight of newLights) group.addWithUpdate(newLight);
      group.set({ height: prevHeight, left: prevLeft, top: prevTop, angle: prevAngle });
      group.meta.spacing = this.lightSpacing;
    }
    if (lightGroups.length > 1) {
      const activeSelection = new fabric.ActiveSelection(lightGroups, { canvas: this.canvas });
      this.canvas.setActiveObject(activeSelection);
    }
    this.canvas.requestRenderAll();
  }

  // Change Size
  changeSize(size: number): void {
    this.lightSize = size;
    const lightGroups = this.canvas.getActiveObjects().filter((object) => {
      if (!object.isType('group')) return false;
      const lightGroup = object as LightGroup;
      return lightGroup.meta !== undefined && lightGroup.meta.type === 'light';
    }) as LightGroup[];
    if (lightGroups.length === 0) return;
    const lights = lightGroups.map((activeObject) => { return activeObject.getObjects(); }).flat() as fabric.Circle[];
    for (const light of lights) {
      light.set({ radius: this.lightSize, left: light.left! - (this.lightSize - light.radius!) });
    }
    for (const group of lightGroups) {
      group.meta.size = this.lightSize;
    }
    this.canvas.requestRenderAll();
  }

  // Change Color
  changeColor(color: string): void {
    this.isPatternActive = false;
    this.lightColor = color;
    const lightGroups = this.canvas.getActiveObjects().filter((object) => {
      if (!object.isType('group')) return false;
      const lightGroup = object as LightGroup;
      return lightGroup.meta !== undefined && lightGroup.meta.type == 'light';
    }) as LightGroup[];
    if (lightGroups.length === 0) return;
    const lights = lightGroups.map((activeObject) => { return activeObject.getObjects(); }).flat() as fabric.Circle[];
    for (const light of lights) {
      light.set({
        fill: this.lightColor,
        shadow: new fabric.Shadow({ affectStroke: true, blur: 5, color: this.lightColor })
      });
    }
    for (const group of lightGroups) {
      group.meta.color = this.lightColor;
      group.meta.isPatternActive = false;
    }
    this.canvas.requestRenderAll();
  }

  /* ----- Custom Colors ----- */

  // Add Custom Color
  addCustomColor(color: string): void {
    if (this.customColors.includes(color)) {
      this.alertService.showWarningAlert('Custom Color Already Exists');
      return;
    }
    this.customColors.push(color);
    localStorage.setItem('MockupTool.CustomColors', JSON.stringify(this.customColors));
  }

  // Remove Custom Color
  removeCustomColor(color: string): void {
    this.customColors = this.customColors.filter(cColor => cColor != color);
    localStorage.setItem('MockupTool.CustomColors', JSON.stringify(this.customColors));
  }

  // Load Stored Custom Colors
  private loadStoredCustomColors(): void {
    const storedData = localStorage.getItem('MockupTool.CustomColors');
    if (storedData === null) return;
    this.customColors = JSON.parse(storedData);
  }

  /* ----- Color Patterns ----- */

  // Add Color Pattern
  addColorPattern(): void {
    this.colorPatterns.push([]);
  }

  // Remove Color Pattern
  removeColorPattern(index: number): void {
    this.colorPatterns = this.colorPatterns.filter((pattern, pIndex) => pIndex !== index);
    localStorage.setItem('MockupTool.ColorPatterns', JSON.stringify(this.colorPatterns));
  }

  // Add Color To Pattern
  private addColorToPattern(index: number, color: string): void {
    // if (this.colorPatterns[index].includes(color)) {
    //   this.alertService.showWarningAlert('Color Already Exists In Pattern');
    //   return;
    // }
    this.colorPatterns[index].push(color);
    localStorage.setItem('MockupTool.ColorPatterns', JSON.stringify(this.colorPatterns));
  }

  // Remove Color From Pattern
  removeColorFromPattern(pIndex: number, cIndex: number): void {
    const newPattern = this.colorPatterns[pIndex].filter((color, idx) => cIndex !== idx);
    if (this.isPatternSelected(this.colorPatterns[pIndex])) this.lightColorPattern = newPattern;
    this.colorPatterns[pIndex] = newPattern;
    localStorage.setItem('MockupTool.ColorPatterns', JSON.stringify(this.colorPatterns));
  }

  // Load Stored Color Patterns
  private loadStoredColorPatterns(): void {
    const storedData = localStorage.getItem('MockupTool.ColorPatterns');
    if (storedData === null) return;
    this.colorPatterns = JSON.parse(storedData);
  }

  // Apply Color Pattern
  applyColorPattern(pattern: string[]): void {
    this.isPatternActive = true;
    this.lightColorPattern = pattern;
    const lightGroups = this.canvas.getActiveObjects().filter((object) => {
      if (!object.isType('group')) return false;
      const lightGroup = object as LightGroup;
      return lightGroup.meta !== undefined && lightGroup.meta.type == 'light';
    }) as LightGroup[];
    if (lightGroups.length === 0) return;
    const lights = lightGroups.map((activeObject) => { return activeObject.getObjects(); }).flat().reverse() as fabric.Circle[];
    var i = 0;
    for (const light of lights) {
      light.set({
        fill: pattern[i % pattern.length],
        shadow: new fabric.Shadow({ affectStroke: true, blur: 5, color: pattern[i % pattern.length] })
      });
      i++;
    }
    for (const group of lightGroups) {
      group.meta.colorPattern = pattern;
      group.meta.isPatternActive = true;
    }
    this.canvas.requestRenderAll();
  }

  /* ----- Dragging ----- */

  // Color Started Dragging
  colorStartedDragging(event: DragEvent, color: string): void {
    event.dataTransfer.setData('color', color);
  }

  // Color Dropped On Pattern
  colorDroppedOnPattern(index: number, event: DragEvent): void {
    this.addColorToPattern(index, event.dataTransfer.getData('color'));
  }

  /* ----- Background ----- */

  // Load Background Image
  private async loadBackgroundImage(): Promise<void> {
    const fileUrl = this.mockupService.getMockupFileDirectURL(this.mockupId);
    fabric.Image.fromURL(fileUrl, (oImg) => {
      this.isBackgroundFilteringAvailable = (oImg.width <= this.currentTextureSize && oImg.height <= this.currentTextureSize);
      this.scaleBackgroundImage(oImg);
      this.canvas.setDimensions({ width: oImg.width * oImg.scaleX, height: oImg.height * oImg.scaleY });
      this.canvas.setBackgroundImage(oImg, this.canvas.requestRenderAll.bind(this.canvas));
    });
  }

  // Scale Background Image
  private scaleBackgroundImage(img: fabric.Image): void {
    const canvasContainer = this.canvasContainer.nativeElement as HTMLDivElement;
    const widthAspectRatio = canvasContainer.clientWidth / img.width;
    const heightAspectRatio = canvasContainer.clientHeight / img.height;
    const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
    (finalAspectRatio < 1) ? img.scale(finalAspectRatio) : img.scale(1);
  }

  // Adjust Background Image Brightness
  adjustBackgroundImageBrightness(event: Event): void {
    this.brightness = parseFloat((event.target as HTMLInputElement).value);
    this.brightnessSubject$.next(this.brightness);
  }

  // Reset Background Image Brightness
  resetBackgroundImageBrightness(): void {
    this.brightness = 0;
    this.brightnessSubject$.next(this.brightness);
  }

  // Listen For Background Image Brightness Changes
  private listenForBackgroundImageBrightnessChanges(): void {
    this.brightnessSubject$.pipe(debounceTime(250), distinctUntilChanged()).subscribe((brightness) => {
      const bgImg = this.canvas.backgroundImage as fabric.Image;
      const brightnessFilter = new fabric.Image.filters.Brightness({ brightness: brightness });
      bgImg.filters.length = 0;
      bgImg.filters.push(brightnessFilter);
      bgImg.applyFilters();
      this.canvas.requestRenderAll();
    });
  }

  /* ----- Image Controls ----- */

  // Adjust Image Opacity
  adjustImageOpacity(event: Event): void {
    this.selectedImageOpacity = parseFloat((event.target as HTMLInputElement).value);
    const image = this.canvas.getActiveObject() as fabric.Image;
    image.set({ opacity: this.selectedImageOpacity });
    // const images = this.canvas.getActiveObjects().filter((object) => {
    //   return object instanceof fabric.Image;
    // });
    // for (const image of images) image.set({ opacity: opacity });
    this.canvas.requestRenderAll();
  }

  /* ----- Preset Assets ----- */

  // Get Preset Assets
  private getPresetAssets(): void {
    this.mockupService.getMockupPresetAssets().subscribe((presetAssets) => {
      for (const presetAsset of presetAssets) {
        if (this.presetAssetMap.has(presetAsset.category)) {
          const tempArray = this.presetAssetMap.get(presetAsset.category);
          tempArray.push(presetAsset);
          this.presetAssetMap.set(presetAsset.category, tempArray);
        } else {
          this.presetAssetMap.set(presetAsset.category, [presetAsset]);
        }
      }
      this.activePresetAssets = this.presetAssetMap.get('BOW');
    });
  }

  // Select Preset Asset Category
  selectPresetAssetCategory(category: string): void {
    this.activePresetAssetCategory = category;
    if (this.presetAssetMap.has(category)) this.activePresetAssets = this.presetAssetMap.get(category);
  }

  // Is Preset Asset Category Active
  isPresetAssetCategoryActive(category: string): boolean {
    return this.activePresetAssetCategory == category;
  }

  // Add Preset Image
  addPresetImage(asset: MockupPresetAsset): void {
    fabric.Image.fromURL(this.mockupService.getMockupPresetAssetDirectURL(asset.id), (oImg) => {
      this.scaleImage(oImg, 0.25);
      this.setImageAssetEventListeners(oImg);
      this.canvas.add(oImg);
      oImg.center();
    }, { crossOrigin: 'anonymous' });
  }

  // Get Mockup Preset Asset Direct URL
  getMockupPresetAssetDirectURL(assetId: string): string {
    return this.mockupService.getMockupPresetAssetDirectURL(assetId);
  }

  /* ----- Custom Assets ----- */

  // Add Custom Image
  addCustomImage(assetURL: string): void {
    fabric.Image.fromURL(assetURL, (oImg) => {
      this.scaleImage(oImg, 0.25);
      this.setImageAssetEventListeners(oImg);
      this.canvas.add(oImg);
      oImg.center();
    }, { crossOrigin: 'anonymous' });
  }

  /* ----- Misc Assets ----- */

  // Set Image Asset Event Listeners
  private setImageAssetEventListeners(img: fabric.Image): void {
    img.on('mousedown:before', () => {
      this.changeMode(Mode.SELECT);
    });
    img.on('mouseup:before', () => {
      if (this.prevMode === Mode.DRAW) this.changeMode(Mode.DRAW);
    });
    img.on('selected', (event) => {
      this.isImageFilteringEnabled = true;
      this.selectedImageOpacity = (event.target as fabric.Image).get('opacity');
    });
  }

  // Create Missing Image Placeholder
  private createMissingImagePlaceholder(img: fabric.Image): void {
    fabric.loadSVGFromURL('../../assets/triangle-exclamation-solid.svg', (objects, options) => {
      // Rect
      const rect = new fabric.Rect({
        originX: 'center',
        originY: 'center',
        top: 0,
        left: 0,
        width: img.width * img.scaleX,
        height: img.height * img.scaleY,
        fill: 'transparent',
        stroke: '#DC3545',
        strokeWidth: 1
      });
      // SVG
      const svgData = fabric.util.groupSVGElements(objects, options);
      svgData.set({
        originX: 'center',
        originY: 'center',
        top: -10,
        left: 0,
        fill: '#DC3545'
      });
      svgData.scale(0.09375);
      // Text
      const text = new fabric.Text('Image Not Found', {
        originX: 'center',
        originY: 'center',
        top: 35,
        fill: '#DC3545',
        fontSize: 12,
        fontFamily: 'Poppins'
      });
      // Group
      const missingImgGroup = new fabric.Group([rect, svgData, text], {
        top: img.top,
        left: img.left,
        width: img.width * img.scaleX,
        height: img.height * img.scaleY,
      });
      missingImgGroup.on('removed', () => {
        this.canvas.remove(img);
      });
      this.canvas.add(missingImgGroup);
    });
  }

  /* ----- Drawing ----- */

  // Start Light Path
  private startLightPath(): void {
    this.isDrawing = true;
    this.isContinued = false;
    this.startPoint = this.mouse.getPoint();
  }

  // Continue Light Path
  private continueLightPath(): void {
    this.endPoint = this.mouse.getPoint();
    const lightGroup = this.getLightGroup(this.startPoint, this.endPoint);
    this.canvas.add(lightGroup);
    this.canvas.setActiveObject(lightGroup);
    this.startPoint = this.endPoint;
    this.isContinued = true;
  }

  // Get Light Group
  private getLightGroup(startPoint: Point, endPoint: Point): LightGroup {
    const radAngle = this.getAngle(startPoint.x, startPoint.y, endPoint.x, endPoint.y);
    const degAngle = radAngle * (180 / Math.PI) + 90;
    const x = (this.isContinued) ? startPoint.x + (this.lightSpacing - this.LIGHT_GROUP_PADDING) * Math.cos(radAngle) : startPoint.x; // Apply spacing and padding offset for continuous drawing.
    const y = (this.isContinued) ? startPoint.y + (this.lightSpacing - this.LIGHT_GROUP_PADDING) * Math.sin(radAngle) : startPoint.y; // Apply spacing and padding offset for continuous drawing.
    const distance = this.getDistance(x, y, endPoint.x, endPoint.y);
    const lightGroup = new LightGroup(undefined, {
      originX: 'center',
      originY: 'bottom',
      left: x,
      top: y,
      height: distance,
      lockScalingX: true,
      padding: this.LIGHT_GROUP_PADDING
    });
    lightGroup.setControlsVisibility({ bl: false, br: false, mb: false, ml: false, mr: false, tl: false, tr: false });
    lightGroup.meta = {
      type: 'light',
      spacing: this.lightSpacing,
      size: this.lightSize,
      color: this.lightColor,
      isPatternActive: this.isPatternActive,
      colorPattern: this.lightColorPattern
    };
    const lights = this.getLights(lightGroup);
    for (const light of lights) lightGroup.addWithUpdate(light);
    lightGroup.set({ left: x, top: y, angle: degAngle });
    this.setLightGroupEventListeners(lightGroup);
    return lightGroup;
  }

  // Get Lights
  private getLights(group: LightGroup): fabric.Circle[] {
    const angle = this.getAngle(group.left!, group.top!, group.left!, group.top! + group.height!);
    var currX = group.left!;
    var currY = group.top!;
    var lights: fabric.Circle[] = [];
    var distanceTraveled = 1;
    var i = 0;
    while (distanceTraveled <= group.height!) {
      let prevX = currX;
      let prevY = currY;
      const lightColor = (group.meta.isPatternActive) ? group.meta.colorPattern[i % group.meta.colorPattern.length] : group.meta.color;
      let light = this.getLight(currX, currY, group.meta.size, lightColor);
      lights.push(light);
      currX += group.meta.spacing * Math.cos(angle);
      currY += group.meta.spacing * Math.sin(angle);
      distanceTraveled += this.getDistance(prevX, prevY, currX, currY);
      i++;
    }
    return lights;
  }

  // Get Light
  private getLight(x: number, y: number, size: number, color: string): fabric.Circle {
    const light = new fabric.Circle({
      left: x,
      top: y,
      radius: size,
      fill: color,
      shadow: new fabric.Shadow({ affectStroke: true, blur: 5, color: color })
    });
    return light;
  }

  toggleOption(option: any) {
    option.selected = !option.selected;
  }

  isSelected(option: any) {
    return option.selected;
  }
  // Set Light Group Event Listeners
  private setLightGroupEventListeners(group: fabric.Group): void {
    group.on('scaling', (event) => {
      const group = event.transform!.target as LightGroup;
      const prevLeft = group.left!;
      const prevTop = group.top!;
      const prevAngle = group.angle!;
      // Resize
      group.set({
        height: group.height! * group.scaleY!,
        scaleY: 1
      });
      // Adjust Lights
      const numOfLights = Math.floor(group.height! / group.meta.spacing);
      if (numOfLights !== group.size()) {
        for (const light of group.getObjects() as fabric.Circle[]) group.remove(light);
        const newLights = this.getLights(group);
        for (const newLight of newLights) group.addWithUpdate(newLight);
        group.set({ left: prevLeft, top: prevTop, angle: prevAngle });
      }
    });
    group.on('mousedown:before', (event) => {
      this.changeMode(Mode.SELECT);
      const group = event.target as LightGroup;
      this.lightSpacing = group.meta.spacing;
      this.lightSize = group.meta.size;
      this.lightColor = group.meta.color;
      this.isPatternActive = group.meta.isPatternActive;
      this.lightColorPattern = group.meta.colorPattern;
    });
    group.on('mouseup:before', () => {
      if (this.prevMode === Mode.DRAW) this.changeMode(Mode.DRAW);
    });
    group.on('selected', () => {
      this.isImageFilteringEnabled = false;
      this.isUngroupingEnabled = false;
    })
  }

  /* ----- Math Functions ----- */

  // Get Distance
  private getDistance(x1: number, y1: number, x2: number, y2: number): number {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.hypot(dx, dy);
  }

  // Get Angle
  private getAngle(x1: number, y1: number, x2: number, y2: number): number {
    const dy = y2 - y1;
    const dx = x2 - x1;
    const t = Math.atan2(dy, dx);
    return (t < 0) ? t + (Math.PI * 2) : t;
  }

  /* ----- Helper Functions ----- */

  // Scale Image
  private scaleImage(img: fabric.Image, multiplier: number = 1): void {
    const canvasContainer = this.canvasContainer.nativeElement as HTMLDivElement;
    const widthAspectRatio = canvasContainer.clientWidth / img.width;
    const heightAspectRatio = canvasContainer.clientHeight / img.height;
    const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
    img.scale(finalAspectRatio * multiplier);
  }

  // Get Icon Color
  getIconColor(hexColor: string): string {
    var r = parseInt(hexColor.substring(1, 3), 16);
    var g = parseInt(hexColor.substring(3, 5), 16);
    var b = parseInt(hexColor.substring(5, 7), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq < 40) ? '#FFFFFF' : '#000000';
  }

  // Is Pattern Selected
  isPatternSelected(pattern: string[]): boolean {
    if (this.lightColorPattern.length !== pattern.length) return false;
    for (const color of pattern) {
      if (!this.lightColorPattern.includes(color)) return false;
    }
    return true;
  }

  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    console.log(screenWidth)
    if(screenWidth <= 600) {
      this.isMobileView =  true;
      this.hideEditMockupTools = false;
    } else if(screenWidth > 600 && screenWidth <= 991) {
      this.hideEditMockupTools = true;
      this.isMobileView = false;
    } else if(screenWidth >= 992) {
      this.hideEditMockupTools = false;
    }

    this.setPointerEvents()

  }

  private setPointerEvents() {
    const canvas = this.canvasContainer.nativeElement;
    if (this.isMobile) {
      canvas.style.pointerEvents = 'none';
    } else {
      canvas.style.pointerEvents = 'auto';
    }
  }


  // Copy Selected
  copySelected() {
    if (!this.canvas.getActiveObject()) return;

    this.canvas.getActiveObject().clone((cloned: fabric.Object) => {
      this.clipboard = cloned;
    });
  }

  // Paste Selected
  pasteSelected() {
    if (!this.clipboard) return;

    this.clipboard.clone((clonedObj: fabric.Object) => {
      this.canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left! + 10,
        top: clonedObj.top! + 10,
        evented: true,
      });

      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas
        clonedObj.canvas = this.canvas;
        (clonedObj as fabric.ActiveSelection).forEachObject((obj: fabric.Object) => {
          this.canvas.add(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        this.canvas.add(clonedObj);
      }

      this.clipboard.set({
        top: this.clipboard.top! + 10,
        left: this.clipboard.left! + 10,
      });

      this.canvas.setActiveObject(clonedObj);
      this.canvas.requestRenderAll();
    });
  }

  cutSelected() {
    this.copySelected();
    this.deleteSelectedObjects();
  }
}
