<nav>
  <div style="grid-row: 1;">
    <div style="padding: 10px 7px">
      <div class="nav-link m-0" (click)="toggle();" ngbTooltip="Expand" placement="right" [disableTooltip]="isOpen">
        <fa-icon class="nav-icon" [icon]="(isOpen) ? faChevronLeft : faChevronRight"></fa-icon>
      </div>
    </div>
    <div *ngIf="isOpen" routerLink="/dashboard">
      <picture>
        <source type="image/webp" srcset="../assets/TinselLogo.webp">
        <source type="image/png" srcset="../assets/TinselLogo.png">
        <img src="../assets/TinselLogo.png" width="80%" class="logo d-block ms-auto me-auto mt-1 mb-2" alt="Tinsel CRM" loading="eager">
      </picture>
    </div>
    <hr>
  </div>
  <!-- Links -->
  <div id="LINKS" style="overflow-y: auto; scrollbar-width: thin; grid-row: 2;">
    <div *ngIf="hasAccess" (click)="setNextRoute('/dashboard')" class="nav-link" routerLink="/dashboard" routerLinkActive="active" ngbTooltip="Dashboard" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faHome" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Dashboard</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/clients')" class="nav-link" routerLink="/clients" routerLinkActive="active" ngbTooltip="Clients" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faUsers" [fixedWidth]="true" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Clients</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/contact-list')" class="nav-link" routerLink="/contact-list" routerLinkActive="active" ngbTooltip="Contacts" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faAddressBook" [fixedWidth]="true" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Contacts</div>
    </div>
    <div *ngIf="hasAccess && loginDetails?.isBeta" (click)="setNextRoute('/conversation')" class="nav-link" routerLink="/conversation" routerLinkActive="active" ngbTooltip="Conversation" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faComments" [fixedWidth]="true" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Communications</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/projects')" class="nav-link" routerLink="/projects" routerLinkActive="active" ngbTooltip="Projects" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faProjectDiagram" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Projects</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/invoices')" class="nav-link" routerLink="/invoices" routerLinkActive="active" ngbTooltip="Invoices" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faFileInvoice" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Invoices</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/inventory')" class="nav-link" routerLink="/inventory" routerLinkActive="active" ngbTooltip="Inventory" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faLightbulb" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Inventory</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/projectPrepOverview')" class="nav-link" routerLink="/projectPrepOverview" routerLinkActive="active" ngbTooltip="Inventory Prep" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faShoppingCart" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Inventory Prep</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/schedule')"  class="nav-link" routerLink="/schedule" routerLinkActive="active" ngbTooltip="Schedule" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faCalendar" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Schedule</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/projectGlance')" class="nav-link" routerLink="/projectGlance" routerLinkActive="active" ngbTooltip="Project Glance" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faImage" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Project Glance</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/userTimeClock')" class="nav-link" routerLink="/userTimeClock" routerLinkActive="active" ngbTooltip="User Time Clock" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faClock" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">User Time Clock</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/timeClock')" class="nav-link" routerLink="/timeClock" routerLinkActive="active" ngbTooltip="Time Entries" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faClock" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Time Entries</div>
    </div>
    <div *ngIf="hasAccess" (click)="setNextRoute('/reports')" class="nav-link" routerLink="/reports" routerLinkActive="active" ngbTooltip="Reports" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faClipboardList" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Reports</div>
    </div>
    <!-- <a class="nav-link" href="https://support.tinselcrm.com" target="_blank" routerLinkActive="active" ngbTooltip="Help" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faCircleQuestion" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Help</div>
    </a> -->
    <div *ngIf="hasAccess" (click)="setNextRoute('/helpdesk')" class="nav-link" routerLink="/helpdesk" routerLinkActive="active" ngbTooltip="Help Desk" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faHeadset" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Help Desk</div>
    </div>
    <div (click)="setNextRoute('/settings')" *ngIf="isAuthorized() && hasAccess" class="nav-link" routerLink="/settings" routerLinkActive="active" ngbTooltip="Settings" placement="right" [disableTooltip]="isOpen">
      <fa-icon [class]="(isOpen) ? 'nav-icon-open' : 'nav-icon'" [icon]="faCogs" [fixedWidth]="true"></fa-icon>
      <div class="ms-1" *ngIf="isOpen">Settings</div>
    </div>
  </div>
  <!-- User Profile -->
  <div style="grid-row: 3">
    <hr>
    <div style="padding: 10px 0px;">
      <div ngbDropdown class="userDropdown" container="body" placement="top-start">
        <fa-layers ngbDropdownToggle>
          <fa-icon [icon]="faCircle" style="color: white;" size="2x"></fa-icon>
          <fa-layers-text [content]="userInitials" style="left: 100%!important; color: black; font-weight: bold; text-align: center;" transform="grow-1 right-7"></fa-layers-text>
        </fa-layers>
        <div ngbDropdownMenu>
          <button *ngIf="hasAccess" (click)="setNextRoute('/userTimeClock')"  routerLink="/userTimeClock" routerLinkActive="active" ngbDropdownItem>User Time Clock</button>
          <button *ngIf="hasAccess" (click)="setNextRoute('/userSettings')" routerLink="/userSettings" routerLinkActive="active" ngbDropdownItem>User Settings</button>
          <div class="dropdown-divider"></div>
          <button (click)="logout();" routerLinkActive="active" ngbDropdownItem>Sign Out</button>
        </div>
      </div>
    </div>
  </div>
</nav>
