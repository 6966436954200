<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 *ngIf="client" class="mobileHeading">{{client.name}}</h2>
    </div>
    <div>
      <button type="button" class="btn btn-danger me-2" (click)="openDeleteClientConfirmationModal();">
        <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
        <span>Delete</span>
      </button>
      <button type="button" class="btn btn-primary" (click)="updateClient();">
        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
        <span>Save</span>
      </button>
    </div>
  </nav>
  <div class="content-wrap">
    <form [formGroup]="clientForm">
      <div class="row">
        <div class="col-lg-4 mt-3">
          <div class="card">
            <div class="card-header">
              <h5>General</h5>
            </div>
            <div class="card-body" style="min-height: 328px;">
              <div class="mb-3">
                <label for="CLIENT_NAME">Name</label>
                <input id="CLIENT_NAME" type="text" class="form-control" formControlName="name" placeholder="John Appleseed" maxlength="250" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.invalid && (name.dirty || name.touched)">
                  <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                  <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 250 characters.</div>
                </div>
              </div>
              <label>Type</label>
              <div class="mb-2">
                <div class="form-check form-check-inline">
                  <input id="CLIENT_TYPE_RESIDENTIAL" class="form-check-input" type="radio" formControlName="type" value="RESIDENTIAL">
                  <label for="CLIENT_TYPE_RESIDENTIAL" class="form-check-label" style="font-weight: normal;">Residential</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="CLIENT_TYPE_COMMERCIAL" class="form-check-input" type="radio" formControlName="type" value="COMMERCIAL">
                  <label for="CLIENT_TYPE_COMMERCIAL" class="form-check-label" style="font-weight: normal;">Commercial</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="CLIENT_TYPE_OTHER" class="form-check-input" type="radio" formControlName="type" value="OTHER">
                  <label for="CLIENT_TYPE_OTHER" class="form-check-label" style="font-weight: normal;">Other</label>
                </div>
              </div>
              <div class="mb-3">
                <label for="CLIENT_LEAD_TYPE">Lead Type</label>
                <input *ngIf="leadTypeTypeaheadInput" id="CLIENT_LEAD_TYPE" type="text" class="form-control" formControlName="leadType" placeholder="Lead Type" maxlength="150" required [ngbTypeahead]="leadTypeTypeaheadInput" (focus)="leadTypeTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="leadType.invalid && (leadType.dirty || leadType.touched)">
                <div *ngIf="leadType.invalid && (leadType.dirty || leadType.touched)">
                  <div *ngIf="leadType.errors.required" class="input-validation-msg">Lead Type is required.</div>
                  <div *ngIf="leadType.errors.maxlength" class="input-validation-msg">Lead Type cannot exceed 150 characters.</div>
                </div>
              </div>
              <div>
                <label for="CLIENT_STATUS">Status</label>
                <select id="CLIENT_STATUS" class="form-select" formControlName="status" required [class.is-invalid]="status.invalid && (status.dirty || status.touched)">
                  <option value="PROSPECT">Prospect</option>
                  <option value="WAIT LIST">Wait List</option>
                  <option value="CUSTOMER">Customer</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
                <div *ngIf="status.invalid && (status.dirty || status.touched)">
                  <div *ngIf="status.errors.required" class="input-validation-msg">Status is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card">
            <div class="card-header">
              <h5>Billing Address</h5>
            </div>
            <div class="card-body">
              <app-address-form [form]="clientForm"></app-address-form>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <app-notes [referenceId]="this.clientId" [referenceIds]="[this.clientId]" type="CLIENT" cardBodyHeight="328px"></app-notes>
        </div>
      </div>
    </form>

    <ul ngbNav #nav="ngbNav" class="nav-tabs mt-3">
      <!-- Contacts Tab -->
      <li ngbNavItem="CONTACTS">
        <a ngbNavLink (click)="getContactsForClient();">Contacts</a>
        <ng-template ngbNavContent>
          <!-- Contacts Table -->
          <div class="card mt-3">
            <div class="card-header">
              <h5>Contacts</h5>
              <div class="ms-auto">
                <button class="btn btn-primary" (click)="openContactModal(contacts.length == 0);">
                  <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                  <span>New</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table *ngIf="!isMobile">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Title</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Primary</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let contact of contacts; let i = index;" (click)="openContactModal(i == 0, contact);">
                      <td data-label="First Name">{{contact.firstName}}</td>
                      <td data-label="Last Name">{{contact.lastName}}</td>
                      <td data-label="Title">{{contact.title}}</td>
                      <td data-label="Phone">{{contact.phone}} <span *ngIf="contact.phoneExtension">x{{contact.phoneExtension}}</span></td>
                      <td data-label="Email">{{contact.email}}</td>
                      <td data-label="Primary">
                        <fa-icon *ngIf="contact.isPrimary" [icon]="faStar"></fa-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div *ngIf="isMobile" class="scrollable">
                  <ul class="list-group">
                      <li *ngFor="let contact of contacts; let i = index;" class="list-group-item" style="cursor: pointer;" (click)="openContactModal(i == 0, contact);">
                          <div class="d-flex list-group-container">
                              <div>
                                  <strong>{{contact.firstName}} {{contact.lastName}} <fa-icon *ngIf="contact.isPrimary" [icon]="faStar"></fa-icon></strong>
                                  <div>{{contact.title}}</div>
                                  <div>{{contact.phone}} <span *ngIf="contact.phoneExtension">x{{contact.phoneExtension}}</span></div>
                                  <div>{{contact.email}}</div>
                              </div>
                          </div>
                      </li>
                      <li *ngIf="contacts?.length == 0" class="list-group-item">No Contacts</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- Sites Tab -->
      <li ngbNavItem="SITES">
        <a ngbNavLink (click)="getSitesForClient();">Sites</a>
        <ng-template ngbNavContent>
          <!-- Sites Table -->
          <div class="card mt-3">
            <div class="card-header">
              <h5>Sites</h5>
              <div class="ms-auto">
                <button *ngIf="client" class="btn btn-primary" (click)="openNewSiteModal();">
                  <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                  <span>New</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table *ngIf="!isMobile">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Street</th>
                      <th>City</th>
                      <th>County</th>
                      <th>State</th>
                      <th>Postal Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let site of sites" routerLink="/sites/{{site.id}}">
                      <td data-label="Name">{{site.name}}</td>
                      <td data-label="Street">{{site.address.street}}</td>
                      <td data-label="City">{{site.address.city}}</td>
                      <td data-label="County">{{site.address.county}}</td>
                      <td data-label="State">{{site.address.state}}</td>
                      <td data-label="Postal Code">{{site.address.postalCode}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="scrollable" *ngIf="isMobile">
                  <ul class="list-group">
                      <li *ngFor="let site of sites" class="list-group-item" style="cursor: pointer;" routerLink="/sites/{{site.id}}">
                          <div class="d-flex list-group-container">
                              <div>
                                  <strong>{{site.name}}</strong>
                                  <div>{{site.address.street}}</div>
                                  <div>{{site.address.city}}</div>
                                  <div>{{site.address.county}}</div>
                                  <div>{{site.address.state}}</div>
                                  <div>{{site.address.postalCode}}</div>
                              </div>
                          </div>
                      </li>
                      <li *ngIf="sites.length == 0" class="list-group-item">No Sites</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- Projects Tab -->
      <li ngbNavItem="PROJECTS">
        <a ngbNavLink>Projects</a>
        <ng-template ngbNavContent>
          <app-client-detail-projects [clientId]="clientId"></app-client-detail-projects>
        </ng-template>
      </li>
      <!-- Invoices Tab -->
      <li ngbNavItem="INVOICES">
        <a ngbNavLink>Invoices</a>
        <ng-template ngbNavContent>
          <!-- Invoices Table -->
          <app-client-detail-invoices [clientId]="clientId"></app-client-detail-invoices>
        </ng-template>
      </li>
      <!-- Files Tab -->
      <li ngbNavItem="FILES">
        <a ngbNavLink>Files</a>
        <ng-template ngbNavContent>
          <!-- Files Table -->
         <app-client-detail-files [clientId]="clientId"></app-client-detail-files>
        </ng-template>
      </li>
      <!-- Vaults -->
      <li ngbNavItem="VAULTS">
        <a ngbNavLink>Tinsel Pay Vaults</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-lg-4 mt-2">
              <app-client-detail-vaults [clientId]="clientId" type="CARD" title="Card Vaults"></app-client-detail-vaults>
            </div>
            <div class="col-lg-4 mt-2">
              <app-client-detail-vaults [clientId]="clientId" type="ACH" title="ACH Vaults"></app-client-detail-vaults>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div id="NAV_OUTPUT" [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
