<div class="content-container mobile-view-container" *ngIf="isMobile && !hideEditMockupTools">
  <div class="centered-text">
    Please rotate your phone
  </div>
</div>

<div class="content-container" *ngIf="!isMobile">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex" style="padding: 0px;">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <div class="content-container" style="display: flex; flex-direction: column;">
                <form [formGroup]="mockupForm">
                    <div class="d-flex">
                        <fa-icon *ngIf="!hideEditMockupTools"  [icon]="faPencil" class="my-auto me-2"></fa-icon>
                        <input *ngIf="!hideEditMockupTools" type="text" class="mobileHeading form-control custom-heading" style="text-overflow: ellipsis;" formControlName="name" placeholder="Mockup 1" maxlength="150" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                        <span  class="mobileHeading form-control custom-heading" *ngIf="hideEditMockupTools">{{mockupForm.get('name')?.value || ''}}</span>
                    </div>
                </form>
                <small *ngIf="lastUpdated" class="text-muted">Updated: {{lastUpdated | formatDateTime}}</small>
            </div>
        </div>
        <div class="mt-2 mt-xl-0">
            <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
                <button type="button" class="btn btn-secondary me-2" ngbDropdownToggle>Options</button>
                <div ngbDropdownMenu aria-labelledby="DROPDOWN">
                    <button ngbDropdownItem (click)="saveToFiles();" [disabled]="isSavingToFiles">
                        <fa-icon class="button-icon-left" [icon]="faUpload"></fa-icon>
                        <span>Export to Files</span>
                    </button>
                    <button ngbDropdownItem (click)="downloadImage();">
                        <fa-icon class="button-icon-left" [icon]="faDownload"></fa-icon>
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <button *ngIf="!hideEditMockupTools" type="button" class="btn btn-danger me-2" (click)="openDeleteMockupConfirmationModal();">
                <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
                <span>Delete</span>
            </button>
            <button *ngIf="!hideEditMockupTools" type="button" class="btn btn-primary" (click)="updateMockup();">
                <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                <span>Save</span>
            </button>
        </div>
    </nav>
    <div class="h-100">
        <div class="mt-2">
            <div class="card">
                <div class="card-body">
                    <strong style="color: var(--bs-red);">{{hideEditMockupTools ? 'Notes': 'Beta Warning'}}</strong>
                    <p class="mb-0" *ngIf="!hideEditMockupTools">The Tinsel CRM Mockup Tool is currently in beta. During this period, it's possible that a future beta update may cause existing mockups to not display correctly. Any exported mockups (images) will not be affected. If you encounter any bugs or issues, please submit a <a href="/helpdesk">bug report</a>. For optimal design, the maximum zoom is 150%. "Please ensure that you save all your changes before zooming in or out.</p>
                    <p class="mb-0" *ngIf="hideEditMockupTools">The Tinsel CRM Mockup Tool feature editing is available in larger screen like Desktop/Laptop etc,. For optimal design, the maximum zoom is 150%. Please make sure that you save all your changes before zooming in or out.</p>
                </div>
            </div>
        </div>
        <div class="mt-2" *ngIf="!hideEditMockupTools">
            <div class="card">
                <div class="card-body d-flex align-items-center">
                    <!-- Mode -->
                    <div class="btn-group me-2" role="group">
                        <button type="button" class="btn btn-primary" [class.active]="mode === ModeType.SELECT" (click)="changeMode(ModeType.SELECT);" ngbTooltip="Select Mode" aria-label="Select Mode"><fa-icon [icon]="faMousePointer"></fa-icon></button>
                        <button type="button" class="btn btn-primary" [class.active]="mode === ModeType.DRAW" (click)="changeMode(ModeType.DRAW);" ngbTooltip="Draw Mode" aria-label="Draw Mode"><fa-icon [icon]="faPencil"></fa-icon></button>
                    </div>
                    <!-- Colors -->
                    <span class="me-2" ngbTooltip="Colors" aria-label="Colors">
                        <button type="button" class="btn btn-primary" [ngbPopover]="lightColorsPopover" popoverTitle="Colors" autoClose="outside" placement="bottom">
                            <div *ngIf="!isPatternActive" class="color-dot" [style.background-color]="lightColor">
                                <fa-icon [icon]="faFillDrip" size="xs" [style.color]="getIconColor(lightColor)"></fa-icon>
                            </div>
                            <div *ngIf="isPatternActive && lightColorPattern.length > 1" class="color-dot" [style]="{ 'background-image': 'linear-gradient(to right,' + lightColorPattern.join(',') + ')' }">
                                <fa-icon [icon]="faFillDrip" size="xs" [style.color]="getIconColor(lightColor)"></fa-icon>
                            </div>
                            <div *ngIf="isPatternActive && lightColorPattern.length == 1" class="color-dot" [style.background-color]="lightColorPattern[0]">
                                <fa-icon [icon]="faFillDrip" size="xs" [style.color]="getIconColor(lightColor)"></fa-icon>
                            </div>
                            <div *ngIf="isPatternActive && lightColorPattern.length == 0" class="color-dot" style="background-color: white;">
                                <fa-icon [icon]="faFillDrip" size="xs" [style.color]="getIconColor(lightColor)"></fa-icon>
                            </div>
                        </button>
                    </span>
                    <!-- Sizing -->
                    <span class="me-2" ngbTooltip="Light Sizing" aria-label="Light Sizing">
                        <button type="button" class="btn btn-primary" [ngbPopover]="lightSizingPopover" popoverTitle="Light Sizing" autoClose="outside" placement="bottom">
                            <fa-icon [icon]="faLightbulb"></fa-icon>
                        </button>
                    </span>
                    <!-- Preset -->
                    <span class="me-2" ngbTooltip="Preset Images" aria-label="Preset Images">
                        <button type="button" class="btn btn-primary" [ngbPopover]="presetAssets" popoverTitle="Preset Images" popoverClass="asset-popover" autoClose="outside" placement="bottom">
                            <fa-icon [icon]="faHollyBerry"></fa-icon>
                        </button>
                    </span>
                    <!-- Custom Images -->
                    <span class="me-2" ngbTooltip="Custom Images" aria-label="Custom Images">
                        <button type="button" class="btn btn-primary" [ngbPopover]="customAssets" #customAssetsPopover="ngbPopover" popoverTitle="Custom Images" popoverClass="asset-popover" autoClose="outside" placement="bottom">
                            <fa-icon [icon]="faImages"></fa-icon>
                        </button>
                    </span>
                    <!-- Divider -->
                    <div *ngIf="objectsSelected" class="vd me-2" style="height: 38px;"></div>
                    <!-- Controls -->
                    <ng-container *ngIf="objectsSelected">
                        <span class="me-2" ngbTooltip="Image Filters" aria-label="Image Filters">
                            <button type="button" class="btn btn-primary" [ngbPopover]="imageFilters" popoverTitle="Image Filters" autoClose="outside" placement="bottom" [disabled]="!isImageFilteringEnabled">
                                <fa-icon [icon]="faTint"></fa-icon>
                            </button>
                        </span>
                        <span class="me-2" ngbTooltip="Group" aria-label="Group">
                            <button type="button" class="btn btn-primary" (click)="groupSelectedObjects();" placement="bottom" [disabled]="!isGroupingEnabled">
                                <fa-icon [icon]="faObjectGroup"></fa-icon>
                            </button>
                        </span>
                        <span class="me-2" ngbTooltip="Ungroup" aria-label="Ungroup">
                            <button type="button" class="btn btn-primary" (click)="ungroupSelectedObjects();" placement="bottom" [disabled]="!isUngroupingEnabled">
                                <fa-icon [icon]="faObjectUngroup"></fa-icon>
                            </button>
                        </span>
                        <span class="me-2" ngbTooltip="Copy or Ctrl + C" aria-label="Copy">
                            <button type="button" class="btn btn-primary" (click)="copySelected();" placement="bottom">
                                <fa-icon [icon]="faCopy"></fa-icon>
                            </button>
                        </span>
                        <span class="me-2" ngbTooltip="Paste or Ctrl + V" aria-label="Paste">
                            <button type="button" class="btn btn-primary" (click)="pasteSelected();" placement="bottom">
                                <fa-icon [icon]="faClipboard"></fa-icon>
                            </button>
                        </span>
                        <span class="me-2" ngbTooltip="Cut or Ctrl + X" aria-label="Cut">
                            <button type="button" class="btn btn-primary" (click)="cutSelected();" placement="bottom">
                                <fa-icon [icon]="faCut"></fa-icon>
                            </button>
                        </span>
                        <span ngbTooltip="Delete" aria-label="Delete">
                          <button type="button" class="btn btn-danger" (click)="deleteSelectedObjects();" placement="bottom">
                              <fa-icon [icon]="faTrashCan"></fa-icon>
                          </button>
                      </span>
                    </ng-container>
                    <!-- Zoom In and Out -->
                    <!-- <div class="d-flex ms-auto">
                      <span class="me-2" ngbTooltip="Zoom Out" aria-label="Zoom Out">
                        <button type="button" class="btn btn-primary" (click)="zoomOut();" placement="bottom">
                            <fa-icon [icon]="faMagnifyingGlassMinus"></fa-icon>
                        </button>
                      </span>
                      <span style="line-height: 2.2;">{{zoomPercentage}}%</span>
                      <span class="ms-2" ngbTooltip="Zoom In" aria-label="Zoom In">
                        <button type="button" class="btn btn-primary" (click)="zoomIn();" placement="bottom">
                            <fa-icon [icon]="faMagnifyingGlassPlus"></fa-icon>
                        </button>
                      </span>
                    </div> -->
                    <!-- Background Brightness -->
                    <!-- <div class="d-flex ms-2"> -->
                    <div class="d-flex ms-auto">
                        <fa-icon class="me-2" [icon]="faSun" style="cursor: pointer;" (click)="resetBackgroundImageBrightness();"></fa-icon>
                        <input type="range" class="w-100" min="-1" max="1" step="0.01" [value]="brightness" (input)="adjustBackgroundImageBrightness($event);" [disabled]="!isBackgroundFilteringAvailable">
                        <span *ngIf="isBackgroundFilteringAvailable" class="text-center ms-2" style="min-width: 47px;">{{brightness | percent}}</span>
                        <span *ngIf="!isBackgroundFilteringAvailable" class="ms-2" style="color: var(--bs-red); cursor: pointer;" ngbPopover="Your device only supports filtering images with a max size of {{currentTextureSize}}x{{currentTextureSize}}." triggers="click">
                            <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Canvas -->
        <div class="mt-2 h-100">
            <div class="card w-100 h-100">
                <div class="card-body w-100 h-100" style="position: relative;">
                  <div *ngIf="hideEditMockupTools || isMobile" class="overlay"></div>
                    <div #CANVAS_CONTAINER class="CANVAS_CONTAINER" style="display: flex; position: absolute; left: 16px; top: 16px; right: 16px; bottom: 16px;width:auto !important;height: auto !important;">
                        <canvas id="CANVAS"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Light Colors -->
<ng-template #lightColorsPopover>
    <!-- Presets -->
    <div class="d-flex flex-column">
        <strong>Presets</strong>
        <div class="mt-1">
            <div class="row row-cols-5">
                <div *ngFor="let color of presetColors; index as i;" class="col text-center px-2">
                    <button type="button" class="color-dot" [style.background-color]="color" (click)="changeColor(color);" draggable="true" (dragstart)="colorStartedDragging($event, color);" ngbTooltip="{{presetColorNames[i]}}">
                        <fa-icon *ngIf="!isPatternActive && lightColor == color" [icon]="faCheck" [style.color]="getIconColor(color)"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Custom -->
    <div class="d-flex flex-column mt-2">
        <strong>Custom</strong>
        <div class="mt-1">
            <div class="row row-cols-5">
                <div *ngFor="let color of customColors; index as i;" class="col text-center px-2">
                    <button type="button" class="color-dot" [style.background-color]="color" (click)="changeColor(color);" (contextmenu)="$event.preventDefault(); removeCustomColor(color);" draggable="true" (dragstart)="colorStartedDragging($event, color);">
                        <fa-icon *ngIf="!isPatternActive && lightColor == color" [icon]="faCheck" [style.color]="getIconColor(color)"></fa-icon>
                    </button>
                </div>
                <div class="col text-center px-2">
                    <button type="button" class="color-dot" style="background-color: #FFFFFF;" colorPicker cpOutputFormat="hex" cpPosition="top-right" [cpSaveClickOutside]="false" cpAlphaChannel="disabled" [cpOKButton]="true" cpOKButtonText="Add" [cpOKButtonClass]="['btn', 'btn-sm', 'btn-primary']" cpCancelButton="true" [cpCancelButtonClass]="['btn', 'btn-sm', 'btn-primary']" (colorPickerSelect)="addCustomColor($event);">
                        <fa-icon [icon]="faPlus" style="padding-left: 0.125rem;"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Patterns -->
    <div class="d-flex flex-column mt-2">
        <strong>Patterns</strong>
        <ul class="list-group mt-1">
            <li *ngFor="let colors of colorPatterns; index as i;" class="list-group-item d-flex px-2" style="cursor: pointer;" (click)="applyColorPattern(colors);" (drop)="colorDroppedOnPattern(i, $event);" (dragover)="$event.preventDefault();">
                <div class="row" [class.row-cols-5]="colors.length > 4">
                    <div *ngFor="let color of colors; index as j" class="col d-flex" [class.mt-2]="j > 4">
                        <span class="color-dot" [style.background-color]="color" (contextmenu)="$event.preventDefault(); removeColorFromPattern(i, j);"></span>
                    </div>
                    <!-- <div class="col" [class.mt-2]="colors.length > 4">
                        <button type="button" class="color-dot" style="background-color: #FFFFFF;" (click)="$event.stopPropagation();" colorPicker cpOutputFormat="hex" cpPosition="top-right" [cpSaveClickOutside]="false" cpAlphaChannel="disabled" [cpOKButton]="true" cpOKButtonText="Add" [cpOKButtonClass]="['btn', 'btn-sm', 'btn-primary']" cpCancelButton="true" [cpCancelButtonClass]="['btn', 'btn-sm', 'btn-primary']" (colorPickerSelect)="addColorToPattern(i, $event);">
                            <fa-icon [icon]="faPlus" style="padding-left: 0.125rem;"></fa-icon>
                        </button>
                    </div> -->
                </div>
                <div class="d-flex ms-auto">
                    <fa-icon *ngIf="isPatternActive && isPatternSelected(colors)" [icon]="faCheck" class="my-auto"></fa-icon>
                    <button type="button" class="btn-close ms-2 my-auto" (click)="$event.stopPropagation(); removeColorPattern(i);"></button>
                </div>
            </li>
            <li class="list-group-item" style="cursor: pointer;" (click)="addColorPattern();">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span class="ms-2">New Empty Pattern</span>
            </li>
        </ul>
    </div>
    <!-- How To -->
    <div class="d-flex flex-column mt-2">
        <strong>How To</strong>
        <ul class="ps-4 mb-0">
            <li class="py-1">Left-click or tap on a color to select or apply.</li>
            <li class="py-1">Right-click or longpress on a custom color to delete.</li>
            <li class="py-1">Left-click and drag a preset or custom color to a pattern to add that color to the pattern.</li>
            <li class="pt-1">Right-click or longpress on a pattern color to remove that color from the pattern.</li>
        </ul>
    </div>
</ng-template>

<!-- Light Sizing -->
<ng-template #lightSizingPopover>
    <!-- Spacing -->
    <div class="d-flex flex-column">
        <label for="SPACING" class="form-label fw-bold mb-1">Spacing</label>
        <div class="d-flex">
            <input id="SPACING" type="range" class="w-100" min="10" max="30" step="1" [value]="lightSpacing" (input)="changeSpacing($event);">
            <span class="ms-2" style="width: 20px;">{{lightSpacing}}</span>
        </div>
    </div>
    <!-- Size -->
    <div class="mt-1">
        <strong>Size</strong>
        <div>
            <div class="form-check form-check-inline">
                <input id="SIZE_XS" type="radio" class="form-check-input" [checked]="lightSize === 2" (change)="changeSize(2);">
                <label for="SIZE_XS" class="form-check-label">XS</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="SIZE_SM" type="radio" class="form-check-input" [checked]="lightSize === 3" (change)="changeSize(3);">
                <label for="SIZE_SM" class="form-check-label">SM</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="SIZE_LG" type="radio" class="form-check-input" [checked]="lightSize === 4" (change)="changeSize(4);">
                <label for="SIZE_LG" class="form-check-label">LG</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="SIZE_XL" type="radio" class="form-check-input" [checked]="lightSize === 5" (change)="changeSize(5);">
                <label for="SIZE_XL" class="form-check-label">XL</label>
            </div>
        </div>
    </div>
</ng-template>

<!-- Preset Assets -->
<ng-template #presetAssets>
    <div class="d-flex flex-column">
        <div class="btn-group btn-group-sm mx-auto">
            <button type="button" class="btn" [class.btn-outline-primary]="!isPresetAssetCategoryActive('BOW')" [class.btn-primary]="isPresetAssetCategoryActive('BOW')" (click)="selectPresetAssetCategory('BOW');">Bows</button>
            <button type="button" class="btn" [class.btn-outline-primary]="!isPresetAssetCategoryActive('GARLAND')" [class.btn-primary]="isPresetAssetCategoryActive('GARLAND')" (click)="selectPresetAssetCategory('GARLAND');">Garland</button>
            <button type="button" class="btn" [class.btn-outline-primary]="!isPresetAssetCategoryActive('WREATH')" [class.btn-primary]="isPresetAssetCategoryActive('WREATH')" (click)="selectPresetAssetCategory('WREATH');">Wreathes</button>
        </div>
        <div class="row scrollable mt-3" style="max-height: 208px;">
            <div *ngFor="let asset of activePresetAssets; index as i;" class="col-6 text-center px-2" [class.mt-2]="i > 1" [ngbTooltip]="asset.name">
                <img [src]="getMockupPresetAssetDirectURL(asset.id)" class="img-thumbnail" width="100" height="100" style="width: 100px; height: 100px; cursor: pointer; object-fit: contain;" (click)="addPresetImage(asset);">
            </div>
        </div>
    </div>
</ng-template>

<!-- Custom Assets -->
<ng-template #customAssets>
    <app-mockup-tool-custom-assets (imageSelected)="addCustomImage($event);" (dismiss)="customAssetsPopover.close();"></app-mockup-tool-custom-assets>
</ng-template>

<!-- Image Filters -->
<ng-template #imageFilters>
    <div>
        <ul class="list-group">
            <li class="list-group-item">
                <div class="d-flex">
                    <strong>Opacity</strong>
                    <span class="ms-auto">{{selectedImageOpacity | percent}}</span>
                </div>
                <input type="range" class="w-100" min="0" max="1" step="0.01" [value]="selectedImageOpacity" (input)="adjustImageOpacity($event);">
            </li>
        </ul>
    </div>
</ng-template>
