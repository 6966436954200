import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { Proposal } from '../proposal';
import { ProposalService } from '../proposal.service';
import { SettingService } from '../setting.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faEye, faPlus, faArrowRight, faRotateLeft, faTriangleExclamation, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Tax } from '../tax';
import { Subject, debounceTime, lastValueFrom } from 'rxjs';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Contact } from '../contact';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { ProjectProposalNoContactModalComponent } from '../project-proposal-no-contact-modal/project-proposal-no-contact-modal.component';

@Component({
  selector: 'app-project-proposal',
  templateUrl: './project-proposal.component.html',
  styleUrls: ['./project-proposal.component.css']
})
export class ProjectProposalComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Output() refresh = new EventEmitter();
  // Properties
  private modalReference: NgbModalRef;
  @Input() project: Project;
  taxes: Tax[] = [];
  proposalForm: UntypedFormGroup;
  projectProposals: Proposal[] = [];
  siteProposals: Proposal[] = [];
  selectedProposal: Proposal;
  isMobileView: boolean = false;

  // Font Awesome Properties
  faEye = faEye;
  faPlus = faPlus;
  faRotateLeft = faRotateLeft;
  faArrowRight = faArrowRight;
  faTriangleExclamation = faTriangleExclamation;
  faPencil = faPencil;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private router: Router,
    private projectService: ProjectService,
    private proposalService: ProposalService,
    private settingService: SettingService,
    private modalService: NgbModal) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.proposalForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      tax: new UntypedFormControl(),
      taxMultiplierName: new UntypedFormControl(),
      cloneProposalId: new UntypedFormControl('NONE'),
      featureItemType: new UntypedFormControl('EXISTING')
    });
    this.proposalForm.controls.tax.disable();
    this.reloadData();
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    // Check if client has contact
    if (this.project.contactEmail) {
      this.modalReference = this.modalService.open(content);
    } else {
      const contactAskModal = this.modalService.open(ProjectProposalNoContactModalComponent);
      contactAskModal.componentInstance.saved.subscribe(() => {
        this.openContactModal();
      });
    }
  }

  openContactModal() {
    const contactModal = this.modalService.open(ContactModalComponent);
    contactModal.componentInstance.clientId = this.project.clientId;
    contactModal.componentInstance.selectedContact = null;
    contactModal.componentInstance.shouldBePrimary = true;
    contactModal.componentInstance.saved.subscribe(() => {
      this.reloadData();
      this.refresh.emit();
    });
  }

  // Reload Data
  private async reloadData(): Promise<void> {
    this.taxes = await lastValueFrom(this.settingService.getTaxes());
    const tax = this.taxes.find((tax) => { return tax.name == this.project.site.address.state; });
    if (tax) {
      this.proposalForm.controls.tax.setValue((tax.multiplier * 100).toFixed(3));
      this.proposalForm.controls.taxMultiplierName.setValue(tax.name);
    } else {
      if (this.taxes.length == 0) {
        this.proposalForm.controls.tax.disable();
      } else {
        this.proposalForm.controls.tax.setValue((this.taxes[0].multiplier * 100).toFixed(3));
        this.proposalForm.controls.taxMultiplierName.setValue(this.taxes[0].name);
      }
    }
    this.getProposalsForProject();
    this.getProposalsForSite();
  }

  // Get Proposals For Project
  getProposalsForProject(): void {
    const params = {
      searchTerm: null,
      sortBy: 'proposal_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:prop.project_id': JSON.stringify([this.project.id])
    };
    this.proposalService.getProposals(params).subscribe((res) => {
      this.projectProposals = res.proposals;
      // if(res.proposals.length != 0) {
      //   this.projectProposals = res.proposals.map(proposal => {
      //     let num = 1;

      //     console.log(proposal)
      //     if(proposal.fromModified) {
      //       const refProposal: Proposal[] = res.proposals.filter(refProp => refProp.id == proposal.id);
      //       if(proposal.status != 'REJECTED' && refProposal.length != 0) num = refProposal[0].rowNumber + 1;
      //     }
      //     return {
      //       ...proposal,
      //       version: num
      //     }
      //   })
      // }
    });
  }

  // Get Proposals For Site
  getProposalsForSite(): void {
    const params = {
      searchTerm: null,
      sortBy: 'proposal_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:proj.site_id': JSON.stringify([this.project.siteId])
    };
    this.proposalService.getProposals(params).subscribe((res) => {
      this.siteProposals = res.proposals.filter(proposal => proposal.status != 'MODIFIED');
    });
  }

  // Create Proposal
  createProposal(): void {
    if (this.proposalForm.value.cloneProposalId == 'NONE') {
      this.addProposal();
    } else {
      this.cloneProposal();
    }
  }

  // Add Proposal
  private addProposal(): void {
    if (this.proposalForm.valid) {
      const proposal = {
        projectId: this.project.id,
        name: this.proposalForm.value.name,
        taxPercent: this.proposalForm.get('tax').value,
        taxMultiplierName: this.proposalForm.value.taxMultiplierName
      };
      this.proposalService.addProposal(proposal).subscribe((proposalId) => {
        this.router.navigateByUrl(`/proposals/${proposalId}`);
      });
    } else {
      this.proposalForm.markAllAsTouched();
    }
  }

  // Clone Proposal
  private cloneProposal(): void {
    if (this.proposalForm.valid) {
      const data: any = {
        proposalId: this.proposalForm.value.cloneProposalId,
        projectId: this.project.id,
        name: this.proposalForm.value.name,
        taxPercent: this.proposalForm.get('tax').value,
        taxMultiplierName: this.proposalForm.value.taxMultiplierName,
        featureItemType: this.proposalForm.value.featureItemType
      };
      this.proposalService.cloneProposal(data).subscribe((proposalId) => {
        this.router.navigateByUrl(`/proposals/${proposalId}`);
      });
    }
  }

  // Reset Project Proposals
  resetProjectProposals(): void {
    this.proposalService.resetProjectProposals(this.project.id).subscribe(() => {
      this.getProposalsForProject();
      this.getProposalsForSite();
      this.projectService.projectUpdated.emit();
    });
  }

  // Select Tax
  selectTax(tax: Tax): void {
    this.proposalForm.controls.tax.setValue((tax.multiplier * 100).toFixed(3));
    this.proposalForm.controls.taxMultiplierName.setValue(tax.name);
  }

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.project.id, 'INVOICE').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }


  // Modify Proposal
  private async modifyProposal(): Promise<void> {
    const proposalLastIndex = this.projectProposals.length - 1;
    const data: any = {
      proposalId: this.projectProposals[proposalLastIndex].id,
      projectId: this.projectProposals[proposalLastIndex].projectId,
      name: this.projectProposals[proposalLastIndex].name,
      taxPercent: this.projectProposals[proposalLastIndex].taxMultiplier * 100,
      taxMultiplierName: this.projectProposals[proposalLastIndex].taxMultiplierName
    };
    this.proposalService.modifyProposal(data).subscribe((proposalId) => {
      this.router.navigateByUrl(`/proposals/${proposalId}`);
    });
  }

  confirmModifyProposal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to modify the proposal?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Continue";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.modifyProposal();
    });
  }

  showResetBtn(): boolean {
    return this.projectProposals.length == 1 &&
      (this.projectProposals[0].status == 'ACCEPTED' || this.projectProposals[0].status == 'REJECTED') &&
      !this.projectProposals[0].hasPaidInvoice;
  }

  showModifyBtn(): boolean {
    const proposalLastIndex = this.projectProposals.length - 1;
    return this.projectProposals.length != 0 &&
      this.projectProposals[proposalLastIndex].status == 'ACCEPTED'
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }

  // Proposal Form Accessors
  get name() { return this.proposalForm.controls.name; }
  get tax() { return this.proposalForm.controls.tax; }
  get cloneProposalId() { return this.proposalForm.controls.cloneProposalId; }
  get featureItemType() { return this.proposalForm.controls.featureItemType; }
}
