<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 *ngIf="invoice" class="mobileHeading">Invoice #{{invoice.number}}<br class="d-md-none"><small class="text-muted ms-md-3"
        routerLink="/clients/{{invoice.clientId}}" style="cursor: pointer;">{{invoice.clientName}}</small></h2>
    </div>
    <div class="button-container">
      <button type="button" class="btn btn-danger ms-2" (click)="unlockInvoiceForm()" *ngIf="invoice && invoice.status != 'PENDING'">
        <fa-icon class="button-icon-left" [icon]="faPencil"></fa-icon>
        <span>Edit</span>
      </button>
      <button type="button" class="btn btn-danger ms-2" (click)="openDeleteInvoiceConfirmationModal();" *ngIf="!isPaid">
        <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
        <span>Delete</span>
      </button>
      <div ngbDropdown class="d-inline-block ms-2" display="dynamic" placement="bottom-right">
        <button id="SEND_DROPDOWN" type="button" class="btn btn-secondary mt-md-0 mt-1" ngbDropdownToggle>Send</button>
        <div ngbDropdownMenu aria-labelledby="SEND_DROPDOWN">
          <button ngbDropdownItem (click)="openSendEmailModalForInvoice();">
            <fa-icon class="button-icon-left" [icon]="faEnvelope"></fa-icon>
            <span>Send Email</span>
          </button>
          <button *ngIf="loginDetails?.isBeta" ngbDropdownItem  (click)="openSendSmsModal();">
            <fa-icon class="button-icon-left" [icon]="faSms"></fa-icon>
            <span>Send SMS</span>
          </button>
        </div>
      </div>
      <button type="button" class="btn btn-info ms-2" (click)="viewInvoice();">
        <fa-icon class="button-icon-left" [icon]="faEye"></fa-icon>
        <span>View</span>
      </button>
      <button class="btn btn-primary ms-2" (click)="updateInvoice();">
        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
        <span>Save</span>
      </button>
    </div>
  </nav>
  <div class="row">
    <div class="col-lg-8 col-xl-8 mt-3">
      <div class="card" style="height: 100%;">
        <div class="card-header">
          <h5>General</h5>
        </div>
        <div class="card-body" style="min-height: 173px;">
          <form [formGroup]="invoiceForm">
            <div class="row">
              <div class="col-sm">
                <div *ngIf="invoice" class="mb-3">
                  <label for="INVOICE_CLIENT_NAME">Client</label>
                  <div class="input-group">
                    <input id="INVOICE_CLIENT_NAME" type="text" class="form-control" [value]="invoice.clientName" disabled readonly>
                    <button type="button" class="btn btn-outline-primary" routerLink="/clients/{{invoice.clientId}}">
                      <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div *ngIf="invoice" class="mb-3">
                  <label for="INVOICE_PROJECT_NAME">Project</label>
                  <div class="input-group">
                    <input id="INVOICE_PROJECT_NAME" type="text" class="form-control" [value]="invoice.projectName" disabled readonly>
                    <button type="button" class="btn btn-outline-primary" routerLink="/projects/{{invoice.projectId}}">
                      <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <label for="INVOICE_STATUS">Status</label>
                <select id="INVOICE_STATUS" class="form-select" formControlName="status">
                  <option value="PENDING">Pending</option>
                  <option value="OVERDUE">Overdue</option>
                  <option value="PAID">Paid</option>
                  <option value="CANCELED">Canceled</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm mt-md-0 mt-3">
                <label>Balance Type</label>
                <div style="margin-top: 0.4rem!important;">
                  <div class="form-check form-check-inline">
                    <input id="INVOICE_BALANCE_TYPE_PERCENT" class="form-check-input" type="radio" formControlName="balanceType" value="PERCENT">
                    <label for="INVOICE_BALANCE_TYPE_PERCENT" class="form-check-label" style="font-weight: normal;">Percent</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="INVOICE_BALANCE_TYPE_DOLLAR" class="form-check-input" type="radio" formControlName="balanceType" value="DOLLAR">
                    <label for="INVOICE_BALANCE_TYPE_DOLLAR" class="form-check-label" style="font-weight: normal;">Dollar</label>
                  </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="generateBalance()">Get Remaining Balance</button>

              </div>
              <div class="col-sm mt-md-0 mt-3">
                <label for="INVOICE_BALANCE">Balance</label>
                <div class="input-group">
                  <div *ngIf="invoiceForm.value.balanceType == 'DOLLAR'" class="input-group-text">$</div>
                  <input id="INVOICE_BALANCE" class="form-control" type="number" formControlName="balance" placeholder="50" min="0" required [class.is-invalid]="balance.invalid && (balance.dirty || balance.touched)">
                  <div *ngIf="invoiceForm.value.balanceType == 'PERCENT'" class="input-group-text">%</div>
                </div>
                <div *ngIf="balance.invalid && (balance.dirty || balance.touched)">
                  <div *ngIf="balance.errors.required" class="input-validation-msg">Balance is required.</div>
                  <div *ngIf="balance.errors.min" class="input-validation-msg">Balance cannot be less than 0.</div>
                </div>
              </div>
              <div class="col-sm mt-md-0 mt-3">
                <label for="INVOICE_ISSUE_DATE">Issue Date</label>
                <div class="input-group">
                  <input id="INVOICE_ISSUE_DATE" class="form-control" formControlName="issueDate" placeholder="YYYY-MM-DD" required ngbDatepicker #issueDatepicker="ngbDatepicker" placement="left" [positionTarget]="issueDatepickerBtn" [class.is-invalid]="issueDate.invalid && (issueDate.dirty || issueDate.touched)">
                  <button #issueDatepickerBtn type="button" class="btn btn-outline-primary" (click)="issueDatepicker.toggle();" [disabled]="isPaid">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                  </button>
                </div>
                <div *ngIf="issueDate.invalid && (issueDate.dirty || issueDate.touched)">
                  <div *ngIf="issueDate.errors.required" class="input-validation-msg">Issue Date is required.</div>
                </div>
              </div>
              <div class="col-sm mt-md-0 mt-3">
                <label for="INVOICE_DUE_DATE">Due Date</label>
                <div class="input-group">
                  <input id="INVOICE_DUE_DATE" class="form-control" formControlName="dueDate" placeholder="YYYY-MM-DD" required ngbDatepicker #dueDatepicker="ngbDatepicker" placement="left" [positionTarget]="dueDatepickerBtn" [class.is-invalid]="dueDate.invalid && (dueDate.dirty || dueDate.touched)">
                  <button #dueDatepickerBtn type="button" class="btn btn-outline-primary" (click)="dueDatepicker.toggle();" [disabled]="isPaid">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                  </button>
                </div>
                <div *ngIf="dueDate.invalid && (dueDate.dirty || dueDate.touched)">
                  <div *ngIf="dueDate.errors.required" class="input-validation-msg">Due Date is required.</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 mt-3">
      <div class="card">
        <h5 class="card-header">Pricing</h5>
        <div class="card-body">
          <!-- Accepted Proposal -->
          <div *ngIf="acceptedProposal" class="d-flex">
            <p class="mb-0" style="font-weight: 600;">Accepted Proposal</p>
            <span class="ms-2" style="cursor: pointer;" [ngbPopover]="acceptedProposalDescriptionPopover" triggers="click">
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
            </span>
            <p class="ms-auto my-auto">{{acceptedProposal.total | currency}}</p>
          </div>
          <!-- Invoice -->
          <div *ngIf="invoice" class="d-flex mt-2">
            <p class="mb-0" style="font-weight: 600;">Invoice</p>
            <p class="ms-auto my-auto">{{invoice.amount | currency}}</p>
          </div>
          <!-- Paid -->
          <div *ngIf="invoice" class="d-flex mt-2">
            <p class="mb-0" style="font-weight: 600;">Paid</p>
            <p class="ms-auto my-auto">{{totalDebitAmount | currency}}</p>
          </div>
          <!-- Refunded -->
          <div *ngIf="totalCreditAmount" class="d-flex mt-2">
            <p class="mb-0" style="font-weight: 600;">Refunded</p>
            <p class="ms-auto my-auto">{{totalCreditAmount | currency}}</p>
          </div>
        </div>
        <div *ngIf="invoice" [ngSwitch]="invoice.status" class="card-footer">
          <div *ngSwitchCase="'PENDING'" class="d-flex">
            <p class="my-auto fs-4" style="font-weight: 700;">Amount Due</p>
            <p class="ms-auto my-auto fs-4">{{invoice.amountDue | currency}}</p>
          </div>
          <p *ngSwitchCase="'OVERDUE'" class="my-auto fs-4 text-center text-danger" style="font-weight: 700;">Overdue</p>
          <p *ngSwitchCase="'PAID'" class="my-auto fs-4 text-center" style="color: var(--green-color); font-weight: 700;">Paid</p>
          <p *ngSwitchCase="'CANCELED'" class="my-auto fs-4 text-center text-danger" style="font-weight: 700;">Canceled</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-lg-8 col-xl-8 mt-3">
      <div class="card w-100">
        <div class="card-header">
          <h5>Payments</h5>
          <div class="ms-auto">
            <button type="button" class="btn btn-primary" (click)="openPaymentModal();">
              <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
              <span>New</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <ul class="list-group scrollable" style="height: 303px;">
            <li *ngFor="let payment of payments" class="list-group-item" style="cursor: pointer;" (click)="openPaymentModal(payment);">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <strong style="line-height: 21px;">{{payment.category | paymentCategory}}</strong>
                    <div [ngSwitch]="payment.status" class="d-flex ms-2">
                      <div *ngSwitchCase="'PENDING'" class="badge text-bg-warning mb-auto">Pending</div>
                      <div *ngSwitchCase="'REJECTED'" class="badge text-bg-danger mb-auto">Rejected</div>
                      <div *ngSwitchCase="'CLOSED'" class="badge text-bg-success mb-auto">Closed</div>
                    </div>
                    <div *ngIf="payment.type == 'CREDIT'" class="badge ms-2" style="background-color: var(--pale-blue-color);">Refund</div>
                  </div>
                  <div *ngIf="payment.transaction" [ngSwitch]="payment.category">
                    <small *ngSwitchCase="'CARD'" class="text-muted">{{payment.transaction.brand}} (&#8226;&#8226;&#8226;&#8226; <span style="font-weight: 600;">{{payment.transaction.lastFour}}</span>)</small>
                    <small *ngSwitchCase="'ACH'" class="text-muted">{{payment.transaction.type}} (<span style="font-weight: 600;">{{payment.transaction.lastFour}}</span>)</small>
                  </div>
                  <small *ngIf="payment.surcharge !== 0" class="text-muted">Fee/Total: {{payment.surcharge | currency}}/{{payment.total | currency}}</small>
                  <small class="text-muted">{{payment.date | formatDateTime: true}}</small>
                </div>
                <div class="ms-auto my-auto fs-4 text-nowrap">{{payment.amount | currency}}</div>
              </div>
            </li>
            <li *ngIf="payments.length == 0" class="list-group-item">No Payments</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 mt-3">
      <app-notes [referenceId]="this.invoiceId" [referenceIds]="[this.invoiceId]" type="INVOICE" cardBodyHeight="335px"></app-notes>
    </div>
  </div>
</div>

<!-- Accepted Proposal Description Popover -->
<ng-template #acceptedProposalDescriptionPopover>
  <p>This number refers to the <span style="font-style: italic;">accepted</span> proposal total, which is a value that is locked-in when the proposal is accepted. If this number is incorrect, then the proposal was most likely modified (i.e., the pricing changed) after it was accepted.</p>
  <p class="mb-0">If the proposal was modified after it was accepted, a warning should be displayed on the proposal page. Click on the warning to view the steps to resolve the discrepancy.</p>
</ng-template>
