import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProjectFileService } from '../project-file.service';
import { ProjectFile } from '../project-file';
import { Subject, debounceTime } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { faAngleLeft, faSave, faTrashCan, faStar, faDownload, faTriangleExclamation, faPlus, faSortUp, faSortDown, faFilter, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { TableControlService } from '../table-control.service';
import { MediaLightboxComponent } from '../media-lightbox/media-lightbox.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-client-detail-files',
  templateUrl: './client-detail-files.component.html',
  styleUrls: ['./client-detail-files.component.css']
})
export class ClientDetailFilesComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Client
  @Input() clientId: string;
  @ViewChild('clientFilePdf') clientFilePdf: TemplateRef<any>;

  // Modal
  private modalReference: NgbModalRef;

  // Project Files
  projectFiles: ProjectFile[] = [];
  selectedProjectFile: ProjectFile;

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faStar = faStar;
  faDownload = faDownload;
  faTriangleExclamation = faTriangleExclamation;
  faPlus = faPlus;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Properties
  isMobileView: boolean = false;
  selectedPdfProjectFile: ProjectFile;

  sortOptions = [
    { label: 'File Name', value: 'pf.project_file_name' },
    { label: 'Site', value: 's.site_name' },
  ];
  filterColumns = [
    { label: 'City', value: 'client_address_city' },
    { label: 'County', value: 'client_address_county' },
    { label: 'Status', value: 'client_status' },
  ];
  sortBy: string = 'pf.project_file_name';
  sortDirection: string = 'ASC';

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor( private projectFileService: ProjectFileService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit() {
    this.tableControls = new TableControlService('ClientDetail.ProjectFIles', true, 'pf.project_file_name', 'DESC');
    this.tableControls.refresh.subscribe(() => {
      this.getFilesForClient();
    });
    this.getFilesForClient();
    this.checkScreenSize();
  }

  // Get Files For Client
  getFilesForClient(): void {
    let params = this.tableControls.getParams();
    params['filter:s.client_id'] = JSON.stringify([this.clientId]);
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.projectFileService.getProjectFiles(params).subscribe((projectFiles) => {
      this.projectFiles = projectFiles;
    });
  }


  // Open Delete File Confirmation Modal
  openDeleteFileConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Deleting this file will remove it from all projects. Are you sure you would like to delete this file?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteProjectFile();
    });
  }


  // Delete Project File
  deleteProjectFile(): void {
    this.projectFileService.deleteProjectFile(this.selectedProjectFile.id).subscribe(() => {
      this.modalReference.close();
      this.getFilesForClient();
    });
  }

  // Download File
  downloadFile(file: ProjectFile): void {
    this.projectFileService.getProjectFilePresignedDownloadUrl(file.id).subscribe((presignedUrl) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = presignedUrl;
      downloadLink.setAttribute('download', file.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  // Open File
  openFile(projectFile: ProjectFile): void {
    if(projectFile.type.includes('pdf')) {
      this.selectedPdfProjectFile = projectFile;
      this.modalService.open(this.clientFilePdf, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
      this.modalReference.componentInstance.lightboxItems = this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).map((projectFile) => { return { id: projectFile.id, type: projectFile.type, name: projectFile.name, url: this.sanitizer.bypassSecurityTrustUrl(projectFile.presignedUrl) }; });
      this.modalReference.componentInstance.selectedLightboxItemId = projectFile.id;
      return;
    }

    if (projectFile && !this.isMedia(projectFile)) return;
    this.modalReference = this.modalService.open(MediaLightboxComponent, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
    this.modalReference.componentInstance.lightboxItems = this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).map((projectFile) => { return { id: projectFile.id, type: projectFile.type, name: projectFile.name, url: projectFile.presignedUrl }; });
    if (projectFile) this.modalReference.componentInstance.selectedLightboxItemId = projectFile.id;

  }

  // Is Media
  isMedia(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image') || projectFile.type.includes('video');
  }

  // Is File Clickable and Viewable
  isFileClickable(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image') || projectFile.type.includes('video') || projectFile.type.includes('pdf');
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getFilesForClient();
  }

}
