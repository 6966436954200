import { Component, OnInit } from '@angular/core';
import { ConversationService } from './conversation.service';
import { TwilioService } from './twilio.service';
import { User } from './user';

@Component({
  selector: 'app-app2',
  templateUrl: './app2.component.html',
  styleUrls: ['./app2.component.css']
})
export class App2Component implements OnInit {

  constructor(
    private twilioService: TwilioService
  ) { }

  ngOnInit(): void {
    const loginDetails:User = JSON.parse(localStorage.getItem('login'))
    if(loginDetails && loginDetails.twilioSID && loginDetails.isBeta){
      this.twilioService.connectTwilio();
    }
  }
}
